import { useAppSelector } from "@app/hooks"
import { useInjectReducer } from "@app/store"
import {
  useLoadOnePackageGroupMutation,
  useSaveOnePackageGroupMutation,
  useLoadPackageDropdownQuery,
} from "@features/packageGroupManage/packageGroupApiSlice"
import packageGroupslice, {
  setOneValue,
  addPackage,
  removePackage,
} from "@features/packageGroupManage/packageGroupslice"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import {
  makeSelectOne,
  groupTypeOptNorm,
  groupTypeOptions,
  viewTypeOptNorm,
  viewTypeOptions,
  packageOptions,
} from "@features/packageGroupManage/packageGroupSelectors"
import { enqueueSnackbar } from "@features/auth/authSlice"
import { Helmet } from "react-helmet"
import {
  APP_NAME,
  CURRENCY_SIGN,
  DATE_FORMAT,
  IMAGE_BASE,
  NoImage,
} from "@utils/constants"
import PageHeader from "@components/PageHeader"
import {
  RiAddCircleFill,
  RiDeleteBin5Line,
  RiDragMove2Line,
  RiEdit2Fill,
  RiExternalLinkFill,
} from "react-icons/ri"
import Button from "@components/Button"
import PageContent from "@components/PageContent"
import moment from "moment"
import Input from "@components/Input"
import SelectWrapper from "@components/Select"
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort"
import { commaNumber } from "@utils/numberHelper"
import { arrayMoveImmutable } from "array-move"
import Checkbox from "@components/Checkbox"
import { slugify } from "@utils/slugHelper"

const PackageSectionAddEdit = () => {
  useInjectReducer("packageGroupManage", packageGroupslice)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()

  const one = useSelector(makeSelectOne)
  const listPackage = useSelector(packageOptions)

  // rtk part
  const [loadOne, { isLoading: loading }] = useLoadOnePackageGroupMutation()
  const { isLoading: loadingPackages } = useLoadPackageDropdownQuery()

  const [
    saveOne,
    { isLoading: saveLoading, isError: saveError, error: saveErrorObj },
  ] = useSaveOnePackageGroupMutation()

  useEffect(() => {
    if (id) {
      loadOne(id)
    }
  }, [])

  const handleChange = (name) => (e) => {
    dispatch(setOneValue({ key: name, value: e.target.value }))
    if (name === "group_name") {
      const slug = slugify(e.target.value)
      dispatch(setOneValue({ key: "group_key", value: slug }))
    }
  }

  const handleChangeDropdown = (name) => (e) => {
    dispatch(setOneValue({ key: name, value: e.value }))
  }

  const handleAddPackage = (e) => {
    if (one.package.map((e) => e._id).includes(e.value)) {
      dispatch(
        enqueueSnackbar({
          message: "Package Already Added.",
          options: {
            variant: "warning",
          },
        }),
      )
    } else {
      dispatch(addPackage(e.value))
    }
  }

  const onSortEnd = (oldIndex, newIndex) => {
    const newArr = arrayMoveImmutable(one.package, oldIndex, newIndex)
    dispatch(setOneValue({ key: "package", value: newArr }))
  }

  const handleRemovePackage = (idx) => {
    dispatch(removePackage(idx))
  }

  const handleCheckChange = (name) => (e) => {
    dispatch(setOneValue({ key: name, value: e.target.checked }))
  }

  const handleSave = () => {
    const { added_at, added_by, is_deleted, __v, ...restData } = one
    const data = pathname.includes("/edit")
      ? {
          ...restData,
          package: restData.package.map((e) => e._id),
        }
      : {
          ...one,
          package: one.package.map((e) => e._id),
        }
    saveOne(data)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          dispatch(
            enqueueSnackbar({
              message: res?.msg || "Package Section saved successfully.",
              options: { variant: "success" },
            }),
          )
          navigate("/admin/package-section-manage")
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err?.data?.msg ?? "Package Section Save Failed.",
            options: {
              variant: "warning",
            },
          }),
        )
      })
  }

  return (
    <>
      <Helmet>
        <title>{`${!id ? "Add" : "Edit"} Package Section : ${APP_NAME}`}</title>
      </Helmet>
      <PageHeader
        title={`${!id ? "Add" : "Edit"} Package Section`}
        isBack
        handleGoBack={() => navigate("/admin/package-section-manage")}
      />
      <PageContent loading={loading}>
        <div className="border border-gray-400 text-sm p-4">
          <Input
            label="Package Section Name"
            value={one?.group_name ?? ""}
            name="group_name"
            id="group_name"
            onChange={handleChange("group_name")}
            divClassName="w-full md:w-1/2 mb-4"
            type="text"
            error={saveError ? saveErrorObj?.data?.errors?.group_name : null}
          />
          <Input
            label="Package Section Key"
            value={one?.group_key ?? ""}
            name="group_key"
            id="group_key"
            onChange={handleChange("group_key")}
            divClassName="w-full md:w-1/2 mb-4"
            type="text"
            error={saveError ? saveErrorObj?.data?.errors?.group_key : null}
          />
          <SelectWrapper
            options={groupTypeOptions}
            label="Group Type"
            onChange={handleChangeDropdown("group_type")}
            divClassName="w-full md:w-1/2 mb-4"
            error={saveError ? saveErrorObj?.data?.errors?.group_type : null}
            value={groupTypeOptNorm[one?.group_type ?? ""]}
            placeholder="Select Group Type"
          />
          <SelectWrapper
            options={viewTypeOptions}
            label="View Type"
            onChange={handleChangeDropdown("view_type")}
            divClassName="w-full md:w-1/2 mb-4"
            error={saveError ? saveErrorObj?.data?.errors?.view_type : null}
            placeholder="Select View Type"
            value={viewTypeOptNorm[one?.view_type ?? ""]}
          />
          <SelectWrapper
            options={listPackage}
            label="Search & Select Packages"
            onChange={handleAddPackage}
            divClassName="w-full md:w-1/2 mb-4"
            error={saveError ? saveErrorObj?.data?.errors?.blog : null}
            value=""
          />
          <fieldset className="p-4 border border-gray-300 my-4">
            <legend className="font-semibold">Selected Package Details</legend>
            {one?.package?.length > 0 ? (
              <>
                <SortableList
                  className="grid grid-cols-4 gap-4"
                  draggedItemClassName="p-4 text-sm bg-white"
                  onSortEnd={onSortEnd}
                >
                  {one.package.map((pack, idx) => (
                    <SortableItem key={`package-${idx}`}>
                      <div className="border cursor-pointer border-gray-500 p-4 mb-3 bg-gray-5 relative hover:shadow-lg">
                        <div className="mb-2 border-b border-dashed pb-2 text-2xl flex justify-between items-center">
                          <SortableKnob>
                            <div>
                              <RiDragMove2Line className="hover:scale-125 duration-200" />
                            </div>
                          </SortableKnob>
                          <button
                            className="rounded-full bg-white border border-danger text-white p-2 shadow-lg"
                            onClick={() => handleRemovePackage(idx)}
                          >
                            <RiDeleteBin5Line className="text-sm text-danger" />
                          </button>
                        </div>
                        <div>
                          <img
                            src={
                              pack.image?.[0]?.path
                                ? `${IMAGE_BASE}${pack.image[0].path}`
                                : NoImage
                            }
                            alt={pack.title}
                            className="w-full h-40 object-cover pointer-events-none"
                            id={`packImg-${idx}`}
                          />
                          <div className="border-l-4 pl-2 flex gap-2 items-center mt-3 border-primary">
                            Title :{" "}
                            <span className="font-medium">{pack.title}</span>
                            <span className="text-primary">
                              <RiExternalLinkFill />
                            </span>
                          </div>
                          <div className="border-l-4 pl-2 flex gap-2 items-center mt-3 border-primary">
                            Price :{" "}
                            <span className="font-medium">
                              {CURRENCY_SIGN}{" "}
                              {pack.price && commaNumber(pack.price)}
                            </span>
                          </div>
                          <div className="border-l-4 pl-2 flex gap-2 items-center mt-3 border-primary">
                            Sales Price :{" "}
                            <span className="font-medium">
                              {CURRENCY_SIGN}{" "}
                              {pack.sales_price &&
                                commaNumber(pack.sales_price)}
                            </span>
                          </div>
                          <div className="border-l-4 pl-2 flex gap-2 items-center mt-3 border-primary">
                            Duration :{" "}
                            <span className="font-medium">{pack.duration}</span>
                          </div>
                        </div>
                      </div>
                    </SortableItem>
                  ))}
                </SortableList>
              </>
            ) : (
              "Package Not Added. Please select to add package."
            )}
          </fieldset>
          <Checkbox
            label="Is Active"
            checked={one?.is_active ?? false}
            name="is_active"
            onChange={handleCheckChange("is_active")}
          />
          <div className="mt-4">
            <Button loading={saveLoading} onClick={handleSave} />
          </div>
        </div>
      </PageContent>
    </>
  )
}

export default PackageSectionAddEdit
