import PropTypes from 'prop-types';
import React from 'react';
import { FaChevronRight } from 'react-icons/fa';

export default function Crumb(props) {
  return (
    <>
      <li>{props.children}</li>
      {!props.isLast && (
        <li className="hidden lg:block">
          <span className="flex items-center px-1">
            <FaChevronRight className="text-xs" />
          </span>
        </li>
      )}
    </>
  );
}

Crumb.propTypes = {
  children: PropTypes.node.isRequired,
};
