import React, { useState } from "react"
import { BsEye, BsEyeSlash } from "react-icons/bs"
import "./style.css"
import { FaExclamationCircle } from "react-icons/fa"

const Input = ({
  label,
  id,
  name,
  onChange,
  value,
  placeholder,
  className,
  labelClassName,
  error,
  type,
  onKeyDown,
  divClassName,
  isTooltip,
  tooltipText,
  ...restProps
}) => {
  return (
    <div className={`flex flex-col ${divClassName ?? ""}`}>
      {label && (
        <label
          htmlFor={name}
          className={`${
            labelClassName ? labelClassName : "font-semibold text-sm"
          }`}
        >
          {label}
          {isTooltip && tooltipText && (
            <div className="tooltip cursor-help">
              <FaExclamationCircle className="text-info" />
              <span className="tooltiptext text-xs">{tooltipText}</span>
            </div>
          )}
        </label>
      )}
      <input
        value={value}
        name={name}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        className={className ? className : "inputbox"}
        type={type}
        onKeyDown={onKeyDown}
        {...restProps}
      />
      {error && <div className="text-xs text-danger">{error}</div>}
    </div>
  )
}

export default Input

export const PasswordInput = ({
  label,
  id,
  name,
  onChange,
  value,
  placeholder,
  className,
  labelClassName,
  error,
  ...restProps
}) => {
  const [secure, setSecure] = useState(true)

  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className={`${
            labelClassName ? labelClassName : "font-semibold text-sm"
          }`}
        >
          {label}
        </label>
      )}
      <div className="relative w-full">
        <input
          value={value}
          name={name}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          className={className ? className : "inputbox w-full"}
          type={secure ? "password" : "text"}
          {...restProps}
        />
        <button
          onClick={() => setSecure(!secure)}
          className="absolute right-0 top-[14px] mr-4 hover:text-primary duration-200"
        >
          {secure ? <BsEyeSlash className=" font-bold" /> : <BsEye />}
        </button>
      </div>
      {error && <div className="text-xs text-danger">{error}</div>}
    </>
  )
}
