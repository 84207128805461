import {
  customStyles,
  customStylesFilter,
} from "@components/Select/customStyles"
import React from "react"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "./styles.css"

export const FilterInput = ({
  label,
  value,
  id,
  onChange,
  error,
  placeholder,
  onKeyDown,
  fieldsetId,
  ...restProps
}) => {
  const handleFocus = () => {
    const fieldset = document.getElementById(fieldsetId)
    if (fieldset) {
      fieldset.style.borderColor = "#2596be"
    }
  }

  const handleBlur = () => {
    const fieldset = document.getElementById(fieldsetId)
    if (fieldset) {
      fieldset.style.borderColor = "#d4d4d8"
    }
  }

  return (
    <fieldset
      className="border border-gray-300 focus:border-primary h-[50px]"
      id={fieldsetId}
    >
      <legend className="text-[12.5px] ml-3">{label}</legend>
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        className="w-full outline-none pt-1 px-3 text-sm"
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...restProps}
      />
    </fieldset>
  )
}

export const FilterSelect = ({
  fieldsetId,
  label,
  options,
  value,
  placeholder,
  onChange,
  styles,
  ...restProps
}) => {
  return (
    <fieldset
      className="border border-gray-300 focus:border-primary h-[46px]"
      id={fieldsetId}
    >
      <legend className="text-[12.5px] ml-3">{label}</legend>
      <Select
        options={options ?? []}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        styles={styles ?? customStylesFilter}
        {...restProps}
      />
    </fieldset>
  )
}

export const FilterDate = ({
  fieldsetId,
  label,
  handleFocus,
  name,
  selected,
  onChange,
  ...restProps
}) => {
  return (
    <fieldset
      className="border border-gray-300 focus:border-primary"
      id={fieldsetId}
    >
      <legend className="text-[12.5px] ml-3">{label}</legend>
      <DatePicker
        id={name}
        className="w-full outline-none px-2 text-sm pb-1"
        selected={selected}
        onChange={onChange}
        {...restProps}
      />
    </fieldset>
  )
}
