import { apiSlice } from "@app/api/apiSlice"

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadAdminNumbers: builder.query({
      query: () => "admindashboard/admin/numbers",
    }),
    loadAdminStaticNumbers: builder.query({
      query: () => "admindashboard/static",
    }),
    loadAdminOrderStatus: builder.query({
      query: () => "admindashboard/order/status",
    }),
    loadAdminOrderByDate: builder.query({
      query: () => "admindashboard/date/order",
    }),
    loadTopProductsNumbers: builder.query({
      query: () => "admindashboard/order/product/amount/5",
    }),
    loadTopProductsQuantity: builder.query({
      query: () => "admindashboard/order/product/quantity/5",
    }),
    loadOrderStatusData: builder.query({
      query: () => "admindashboard/order/status",
    }),
    loadStockData: builder.query({
      query: () => "admindashboard/product/stock/warning?&size=5",
    }),
    loadOrderPaymentData: builder.query({
      query: () => "admindashboard/order/payment",
    }),
    loadUsersList: builder.query({
      query: () => "user?size=50",
    }),
    loadUsersByDate: builder.query({
      query: () => "admindashboard/user/day/30",
    }),
    loadLoginData: builder.query({
      query: () => "admindashboard/user/login/method",
    }),
  }),
})

export const {
  useLoadAdminNumbersQuery,
  useLoadAdminStaticNumbersQuery,
  useLoadAdminOrderStatusQuery,
  useLoadAdminOrderByDateQuery,
  useLoadTopProductsNumbersQuery,
  useLoadTopProductsQuantityQuery,
  useLoadOrderStatusDataQuery,
  useLoadStockDataQuery,
  useLoadOrderPaymentDataQuery,
  useLoadUsersListQuery,
  useLoadUsersByDateQuery,
  useLoadLoginDataQuery,
} = dashboardApiSlice
