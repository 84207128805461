/**
 *
 * Dialog
 *
 */

import { useEffect, useRef, useState } from "react"
import { RiCloseFill } from "react-icons/ri"

function useComponentVisible(initialIsVisible, setShowList) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    // uses ref to check if outside of Div is clicked
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
      if (setShowList === undefined) {
        console.log("!! onClose function not passed to dialog component. !!")
      } else {
        setShowList(false)
      }
    }
  }

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowList(false)
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true)
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true)
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

const Dialog = ({
  open,
  onClose,
  className,
  title,
  body,
  actions,
  preventClose,
  bodyClassName,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(open, onClose)

  const children = (
    <div className="fixed left-0 top-0 right-0 bottom-0 z-50 overflow-y-auto overflow-x-hidden bg-black/50">
      <div
        className="w-full absolute top-0 left-0 bottom-0 right-0"
        onClick={preventClose ? null : onClose}
      />
      <div
        className={`z-50 absolute top-20 left-1/2 -translate-x-1/2 shadow-2xl bg-white rounded-lg mx-auto ${
          className && className !== "" ? className : "max-w-xl"
        } `}
      >
        {title !== undefined && (
          <div className="flex items-center justify-between px-4 py-2 border-b border-gray-300">
            <h3 className="m-0 text-base mr-2 text-black">{title}</h3>
            <button
              type="button"
              className="text-black text-xl"
              onClick={onClose}
            >
              <RiCloseFill />
            </button>
          </div>
        )}
        {body !== undefined && (
          <div className={bodyClassName ? bodyClassName : "p-4 w-full"}>
            {body}
          </div>
        )}
        {actions !== undefined && (
          <div className="p-2 flex justify-end">{actions}</div>
        )}
      </div>
    </div>
  )

  return open ? <dialog open={open}>{children}</dialog> : null
}

export default Dialog
