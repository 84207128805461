import React from "react"
import { useInjectReducer } from "@app/store"
import editorFlieSelectSlice from "@features/editorFileSelect/editorFlieSelectSlice"
import FileList from "./components/FileList"
import { Helmet } from "react-helmet"
import { APP_NAME } from "@utils/constants"

const EditorFileSelect = ({ isComponent, selectFile }) => {
  useInjectReducer("editorFileSelect", editorFlieSelectSlice)

  return (
    <>
      {!isComponent && (
        <Helmet>
          <title>{`Media Manage : ${APP_NAME}`}</title>
        </Helmet>
      )}
      <FileList isComponent={isComponent} selectFile={selectFile} />
    </>
  )
}

export default EditorFileSelect
