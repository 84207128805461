import React from "react"
import { Provider } from "react-redux"
import { store } from "./app/store"
import "./index.css"
import { createRoot } from "react-dom/client"
import { Routes } from "./routes"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

// for toast
import Notifier from "@components/Notifier"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const router = createBrowserRouter([...Routes])

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <Notifier />
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-left"
        pauseOnHover="true"
        closeOnClick="true"
        autoClose={3000}
        hideProgressBar="true"
      />
    </Provider>
  </React.StrictMode>,
)
