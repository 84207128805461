import ColoredScrollbars from "@components/ColoredScrollbars"
import { useEffect, useState } from "react"
import { FaFile, FaRegMinusSquare, FaRegPlusSquare } from "react-icons/fa"
import Loader from "@assets/img/loading_transparent.gif"
import {
  useLoadCategoryListQuery,
  useLoadSingleCategoryMutation,
} from "@features/category/categoryApiSlice"
import { useAppSelector } from "@app/hooks"

const SidebarCategoriesList = (props) => {
  const { access } = useAppSelector("auth")
  const {
    setInnerStateValue,
    clearGeneralInfo,
    categoryData,
    loading,
    catQuery,
    setCatQuery,
  } = props
  const [openSet, setOpenSet] = useState({})
  const [query, setQueryValue] = useState({})

  const [loadOne] = useLoadSingleCategoryMutation()

  const category = categoryData?.data ?? []

  const handleSetClick = (key) => {
    setOpenSet({ ...openSet, [key]: !openSet[key] })
  }

  const handleClick = (id) => {
    loadOne(id)
  }

  const handleCollapse = () => {
    setOpenSet({})
  }

  const handleChange = (name, value) => {
    clearGeneralInfo()
    setInnerStateValue({ state: "generalInfo", key: name, value })
  }

  // TODO:look afterward
  const handleExpand = () => {
    const ids = []
    const idsObj = {}
    category.map((each) => {
      ids.push(each._id)
      each.child_category
        ? each.child_category.map((each1) => {
            ids.push(each1._id)
          })
        : ""
    })
    ids.map((e) => (idsObj[e] = true))
    setOpenSet(idsObj)
  }

  const categoryFunctn = (e, parentId = "") => {
    return (
      <ul key={`show-category-${e._id}`}>
        {e.child_category && e.child_category.length ? (
          <>
            <li
              key={e._id}
              className="pt-1 pb-1 pr-4 pl-4 cursor-pointer flex items-center capitalize text-gray-300 hover:text-white text-sm"
              onClick={() => handleSetClick(e._id)}
            >
              {openSet[e._id] ? (
                <div className="flex items-center text-gray-300 hover:text-white cursor-pointer">
                  <FaRegMinusSquare className="text-white text-xs" />
                </div>
              ) : (
                <div className="flex items-center text-grey-darker text-gray-300 hover:text-white cursor-pointer">
                  <FaRegPlusSquare className="text-white text-xs" />
                </div>
              )}
              <div className="flex items-center">
                <span
                  onClick={() => handleClick(e._id)}
                  className="dropdown-title capitalize ml-2"
                >
                  {e.title}
                </span>
              </div>
            </li>

            {!openSet[e._id] ? null : (
              <div className="list-reset pl-6">
                {e.child_category.map((el) => (
                  <div className="border-l border-gray-400" key={el._id}>
                    {categoryFunctn(el, e._id)}
                  </div>
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            {e._id === "" ? (
              <div
                onClick={() => handleChange("parent_category", parentId)}
                className="pt-1 pb-1 pr-4 pl-4 cursor-pointer flex items-center capitalize text-gray-300 hover:text-white text-sm"
              >
                <FaRegPlusSquare className="text-xs" />
                Add subcategory
              </div>
            ) : (
              <div
                onClick={() => handleClick(e._id)}
                className="pt-1 pb-1 pr-4 pl-4 cursor-pointer flex items-center capitalize text-gray-300 hover:text-white text-sm"
              >
                <FaFile className="mr-2 text-xs" />
                {`${e.title}`}
              </div>
            )}
          </>
        )}
      </ul>
    )
  }

  return (
    <div className="list-reset flex flex-col h-full">
      <div className="mx-4 mb-2">
        <input
          onChange={(e) => setCatQuery(e.target.value)}
          placeholder="Search Category"
          className="inputbox w-full"
          value={catQuery.find_category}
          style={{
            background: "#1f2937",
            borderColor: "#1f2937",
            color: "#FFFFFF",
          }}
        />
      </div>
      <div className="px-4 mb-4">
        {/* {access.includes("ADD") && ( */}
        <button
          className="text-gray-300 w-full p-2 font-bold rounded border border-white text-center"
          type="button"
          onClick={() => clearGeneralInfo()}
        >
          Add New Category
        </button>
        {/* )} */}
      </div>
      <div className="px-4">
        <button
          className="mb-2 text-gray-300 hover:text-white"
          type="button"
          onClick={handleCollapse}
        >
          Collapse All
        </button>
        <span className="text-gray-300 px-4">|</span>
        <button
          className="text-gray-300 hover:text-white justify-around mb-2"
          type="button"
          onClick={handleExpand}
        >
          Expand All
        </button>
      </div>

      <ColoredScrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        style={{
          width: 288,
          flex: 1,
        }}
      >
        {loading ? (
          <img
            src={Loader}
            alt="loader"
            className="m-auto mt-10"
            height="30px"
            width="30px"
          />
        ) : (
          category.map((e) => <div key={e._id}>{categoryFunctn(e)}</div>)
        )}
        {!loading && category.length < 0 && <p>No Category</p>}
      </ColoredScrollbars>
    </div>
  )
}

export default SidebarCategoriesList
