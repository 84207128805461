import { INTL_NUMBER_FORMAT } from "./constants"
const invalidNumber = ["e", "+", "-"] // these are the character that are to be prevented in input field of type number

// this function prevents above specified invalidNumber array elements to be prevented to be entered in input field of type number

/* USE CASE :
         <Input
           label="Order"
           inputid="grid-order"
           type="number"
           onKeyDown={evt => numberHelper(evt)}
           value={one.order}
           onChange={handleChange('order')}
           error={errors.order}
         />
*/
export const commaNumber = (number) =>
  new Intl.NumberFormat(
    INTL_NUMBER_FORMAT,
    // {
    //   maximumSignificantDigits: 3,
    // }
  ).format(number)

export const decimalNumber = (number) => {
  if (Number.isInteger(Number(number))) {
    return Number(number)
  } else {
    return Number(number).toFixed(2)
  }
}

export const numberHelper = (evt) =>
  invalidNumber.includes(evt.key) && evt.preventDefault()
