import { useAppSelector } from "@app/hooks"
import Input from "@components/Input"
import React, { useMemo, useState } from "react"
import Loader from "@assets/img/loading_transparent.gif"
import { FaCheck, FaTimesCircle } from "react-icons/fa"
import Dialog from "@components/Dialog"
import { Link, useLocation } from "react-router-dom"
import { IMAGE_BASE, PUBLIC_BASE } from "@utils/constants"
import { getCategoryDropdownOptions } from "@components/GetCategory"
import { CustomOption, customStyles } from "@components/Select/customStyles"
import SelectWrapper from "@components/Select"
import Checkbox from "@components/Checkbox"
import { useDispatch } from "react-redux"
import { setOneValue, clearOne } from "@features/category/categorySlice"
import { slugify } from "@utils/slugHelper"
import Button from "@components/Button"
import { useSaveCategoryMutation } from "@features/category/categoryApiSlice"
import { enqueueSnackbar } from "@features/auth/authSlice"
import EditorFileSelect from "@containers/Admin/EditorFileSelect"

const GeneralInfo = ({ category, product_type }) => {
  const { access } = useAppSelector("auth")
  const { pathname } = useLocation()
  const { loading, one: generalInfo } = useAppSelector("categoryManage")
  const dispatch = useDispatch()
  const [saveCategory, { isLoading: saveLoading }] = useSaveCategoryMutation()

  // state part;
  const [state, setState] = useState({
    images: {
      // image: defaultImage,
      image: "",
    },
    openMedia: false,
  })

  const handleTitleChange = (e) => {
    dispatch(setOneValue({ key: "title", value: e.target.value }))
    const url = slugify(e.target.value)
    dispatch(setOneValue({ key: "url", value: url }))
  }

  const handleChange = (name) => (e) => {
    dispatch(setOneValue({ key: name, value: e.target.value }))
  }

  const handleCheckedChange = (name) => (e) => {
    dispatch(setOneValue({ key: name, value: e.target.checked }))
  }

  const handleDropDownChange = (name) => (e) => {
    dispatch(setOneValue({ key: name, value: e.value }))
  }

  const handleSave = () => {
    saveCategory(generalInfo)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          console.log("success")
          dispatch(clearOne())
          dispatch(
            enqueueSnackbar({
              message: res?.msg || "Category Save Successfull.",
              options: {
                variant: "success",
              },
            }),
          )
        }
      })
      .catch((err) => {
        console.log(err, "err")
        dispatch(
          enqueueSnackbar({
            message: res?.msg || "Category Save Failure.",
            options: {
              variant: "warning",
            },
          }),
        )
      })
  }

  const { listCategoryNormalized, listCategory } = useMemo(() => {
    const options = getCategoryDropdownOptions(category)
    const listCategoryNormalized = options.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.value]: curr,
      }),
      {},
    )
    return {
      listCategoryNormalized,
      listCategory: options,
    }
  }, [category])

  const { listProductType, listProductTypeNormalized } = useMemo(() => {
    let listProductTypeNormalized = {}
    return {
      listProductType: product_type
        ? product_type.map((each) => {
            let obj = {
              label: each.product_type_name,
              value: each._id,
            }
            listProductTypeNormalized = {
              ...listProductTypeNormalized,
              [each._id]: obj,
            }
            return obj
          })
        : [],
      listProductTypeNormalized,
    }
  }, [product_type])

  const handleOpenMedia = () => {
    setState({ openMedia: true })
  }

  const handleCloseMedia = () => {
    setState({ openMedia: false })
  }

  const handleImageChange = (key, file) => {
    dispatch(setOneValue({ key, value: file }))
    setState({ openMedia: false })
  }

  const handleRemoveImage = (key) => {
    dispatch(setOneValue({ key, value: null }))
  }

  return (
    <>
      <Dialog
        className="w-5/6 overflow-auto"
        open={state.openMedia || false}
        onClose={handleCloseMedia}
        title={`Select Media`}
        body={
          <div>
            <EditorFileSelect
              selectFile={(file) => handleImageChange("image", file)}
              isComponent
            />
            <div className="mt-2 text-xs">
              Note: Please Double Click to open folder and select images.
            </div>
          </div>
        }
      />
      <Dialog
        className="w-5/6 overflow-auto"
        open={state.openMedia || false}
        onClose={handleCloseMedia}
        title={`Select Media`}
        body={
          <div>
            <EditorFileSelect
              selectFile={(file) => handleImageChange("banner_image", file)}
              isComponent
            />
            <div className="mt-2 text-xs">
              Note: Please Double Click to open folder and select images.
            </div>
          </div>
        }
      />
      {loading ? (
        <div className="" style={{ height: "532px" }}>
          <img className="h-16 m-auto mt-12" src={Loader} alt="loader" />
        </div>
      ) : (
        <div className="w-full mt-4">
          <div className="flex flex-wrap justify-between px-2">
            <div className="w-full md:w-1/2 pb-4 -ml-2">
              <Input
                label="Title"
                inputid="product-category-title"
                value={generalInfo?.title || ""}
                name="title"
                onChange={handleTitleChange}
                // error={errors.title}
              />
            </div>
            <div className="w-full md:w-1/2 pb-4 -mr-2">
              <Input
                label={
                  <>
                    <span>{"URL "}</span>
                    {generalInfo.url && (
                      <Link
                        to={{
                          pathname: `${PUBLIC_BASE}/category/${generalInfo.url}`,
                        }}
                        className="ml-1 cursor-pointer text-blue-700 text-xs"
                        target="_blank"
                      >
                        (Open URL)
                      </Link>
                    )}
                  </>
                }
                inputid="product-category-url"
                value={generalInfo?.url || ""}
                name="url"
                onChange={handleChange("url")}
                // error={props.errors.url}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-between px-2">
            <div className="w-full md:w-1/2 pb-4 -ml-2">
              <label
                className="font-bold text-gray-700"
                htmlFor="grid-last-name"
              >
                Category
              </label>
              <SelectWrapper
                value={listCategoryNormalized[generalInfo.parent_category]}
                onChange={handleDropDownChange("parent_category")}
                options={[{ label: "Parent Category" }, ...listCategory]}
                components={{ Option: CustomOption }}
                className="React_Select"
                styles={customStyles}
                classNamePrefix="custom-select"
                placeholder="Category Type"
              />

              {/* {errors.parent_category && (
                <div id="component-error-text">{errors.parent_category}</div>
              )} */}
            </div>
            <div className="w-full md:w-1/2 pb-4 -mr-2">
              <label
                className="font-bold text-gray-700"
                htmlFor="grid-last-name"
              >
                Product Type
              </label>

              <SelectWrapper
                className="React_Select"
                id="product_type"
                value={
                  listProductTypeNormalized[generalInfo.product_type] || null
                }
                classNamePrefix="select"
                placeholder="Product Type"
                name="product_type"
                onChange={handleDropDownChange("product_type")}
                isSearchable
                options={listProductType}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-between px-2">
            <div className="w-full md:w-1/2 pb-4 -ml-2">
              <Input
                label="Meta Tag Title"
                inputid="product-category-meta_tag_title"
                value={generalInfo.meta_tag_title || ""}
                name="meta_tag_title"
                onChange={handleChange("meta_tag_title")}
              />
            </div>
            <div className="w-full md:w-1/2 pb-4 -mr-2">
              <Input
                label="Meta Tag Keyword"
                inputid="product-category-meta_tag_keyword"
                value={generalInfo.meta_tag_keyword || ""}
                name="meta_tag_keyword"
                onChange={handleChange("meta_tag_keyword")}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-between px-2">
            <div className="w-full md:w-1/2 pb-4 -ml-2 flex flex-col">
              <label className="font-bold text-gray-700" htmlFor="description">
                Description
              </label>
              <textarea
                className="inputbox"
                id="product-category-description"
                type="text"
                value={generalInfo.description || ""}
                name="description"
                onChange={handleChange("description")}
              />
              {/* <div id="component-error-text">{errors.title}</div> */}
            </div>
            <div className="w-full md:w-1/2 pb-4 -mr-2 flex flex-col">
              <label
                className="font-bold text-gray-700"
                htmlFor="meta-tag-description"
              >
                Meta tag Description
              </label>
              <textarea
                className="inputbox"
                id="product-category-meta_tag_description"
                type="text"
                value={generalInfo.meta_tag_description || ""}
                name="meta_tag_description"
                onChange={handleChange("meta_tag_description")}
              />
              {/* <div id="component-error-text">{errors.title}</div> */}
            </div>
          </div>

          <div className="flex flex-wrap justify-between px-2">
            <div className="w-full md:w-1/2 pb-4 -ml-2">
              <div className="w-full">
                {/* <Dialog
                  className="w-5/6"
                  open={state.openMedia || false}
                  onClose={handleClose}
                  title={`Select Media`}
                  body={
                    <div>
                      <EditorFileSelect
                        location={location}
                        selectFile={(file) => handleImageChange(file)}
                      />
                      <div className="mt-2 text-xs">
                        Note: Please Double Click to open folder and select
                        images.
                      </div>
                    </div>
                  }
                /> */}
                <label htmlFor="Image" className="font-bold text-gray-700">
                  Image
                </label>
                <br />
                <div className="flex justify-between px-2">
                  <div className="w-full  pb-4 -mr-2">
                    {generalInfo &&
                    generalInfo.image &&
                    generalInfo.image.path ? (
                      <div>
                        <section>
                          <div className="relative p-2 flex border border-gray-300">
                            <img
                              src={`${IMAGE_BASE}${generalInfo.image.path}`}
                              className="m-auto h-24 object-contain"
                            />
                            <button
                              type="button"
                              className="absolute -top-[10px] -right-[20px] "
                              onClick={() => handleRemoveImage("image")}
                            >
                              <FaTimesCircle className="text-lg text-red-400 hover:text-red-500" />
                            </button>
                          </div>
                        </section>
                      </div>
                    ) : (
                      <section onClick={handleOpenMedia} className="w-full">
                        <button
                          type="button"
                          className="w-full text-black hover:border-primary hover:text-primary text-center self-start py-5 px-10 border border-gray-500 rounded-lg border-dashed cursor-pointer"
                        >
                          Upload Image from Media
                        </button>
                      </section>
                    )}

                    {/* {errors && errors.image && (
                      <div id="component-error-text">{errors.image}</div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 pb-4 -mr-2">
              <div className="w-full">
                <label
                  htmlFor="banner_image"
                  className="font-bold text-gray-700"
                >
                  Banner Image
                </label>
                <br />
                <div className="flex justify-between px-2">
                  <div className="w-full  pb-4 -mr-2">
                    {generalInfo &&
                    generalInfo.banner_image &&
                    generalInfo.banner_image.path ? (
                      <div>
                        <section>
                          <div className="relative p-2 flex border border-gray-300">
                            <img
                              src={`${IMAGE_BASE}${generalInfo.banner_image.path}`}
                              className="m-auto h-24 object-contain"
                            />
                            <button
                              type="button"
                              className="absolute -top-[10px] -right-[20px] "
                              onClick={() => handleRemoveImage("banner_image")}
                            >
                              <FaTimesCircle className="text-lg text-red-400 hover:text-red-500" />
                            </button>
                          </div>
                        </section>
                      </div>
                    ) : (
                      <section onClick={handleOpenMedia} className="w-full">
                        <button
                          type="button"
                          className="w-full text-black hover:border-primary hover:text-primary text-center self-start py-5 px-10 border border-gray-500 rounded-lg border-dashed cursor-pointer"
                        >
                          Upload Banner Image from Media
                        </button>
                      </section>
                    )}

                    {/* {errors && errors.banner_image && (
                      <div id="component-error-text">{errors.banner_image}</div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 pb-4">
            <Checkbox
              name="is_active"
              checked={generalInfo.is_active || false}
              label="Is Active"
              onChange={handleCheckedChange("is_active")}
            />

            {/* <div className="checkbox">
            <input
              checked={generalInfo.is_reward_applicable || false}
              tabIndex={-1}
              onClick={handleCheckedChange('is_reward_applicable')}
              id="is_reward_applicable"
              type="checkbox"
              onChange={handleCheckedChange('is_reward_applicable')}
            />
            <labell htmlFor="is_reward_applicable">
              <span className="box">
                <FaCheck className="check-icon" />
              </span>
              Is Reward Applicable
            </labell>
          </div> */}
            {/* <div className="checkbox">
            <input
              checked={generalInfo.change_seller_commission || false}
              tabIndex={-1}
              onClick={handleCheckedChange('change_seller_commission')}
              id="change_seller_commission"
              type="checkbox"
              onChange={handleCheckedChange('change_seller_commission')}
            />
            <label htmlFor="change_seller_commission">
              <span className="box">
                <FaCheck className="check-icon" />
              </span>
              Reflect New Commission In All Seller
            </label>
          </div> */}
            <Checkbox
              name="is_medical_product"
              checked={generalInfo.is_medical_product || false}
              label="Is Medical Product"
              onChange={handleCheckedChange("is_active")}
            />
          </div>
          <div className="flex mt-4">
            <button
              type="button"
              className="btn bg-success mr-2"
              onClick={() => dispatch(clearOne())}
            >
              Clear
            </button>
            {access.includes(`${pathname}`) && (
              <Button
                title="Save"
                loadingTitle="Saving"
                className="px-3 py-2 rounded-md bg-info text-white mr-2"
                loading={saveLoading}
                onClick={handleSave}
              />
            )}

            {generalInfo._id && (
              <button
                type="button"
                className="btn bg-danger"
                // onClick={() => handleOpen(generalInfo._id)}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default GeneralInfo
