import { APP_NAME } from "@utils/constants"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import SidebarCategoriesList from "./components/SidebarCategoriesList"
import Input from "@components/Input"
import GeneralInfo from "./components/GeneralInfo"
import {
  useLoadCategoryListQuery,
  useLoadProductTypeOptionsQuery,
} from "@features/category/categoryApiSlice"
import categorySlice from "@features/category/categorySlice"
import { useInjectReducer } from "@app/store"

const CategoryManage = () => {
  useInjectReducer("categoryManage", categorySlice)

  const [tabVal, setTabVal] = useState("General")
  const [catQuery, setCatQuery] = useState("")

  const {
    data: categoryData,
    isLoading: categoryLoading,
    isError,
    refetch: refetchCategory,
  } = useLoadCategoryListQuery(catQuery)

  const {
    data: productTypeData,
    isLoading: productTypeLoading,
    isError: productTypeError,
  } = useLoadProductTypeOptionsQuery()

  return (
    <>
      <Helmet>
        <title>{`Category Manage : ${APP_NAME}`}</title>
      </Helmet>
      <div
        className="flex justify-between"
        style={{ minHeight: "calc(100vh - 150px)" }}
      >
        <div className="rounded bg-gray-700 w-72 pt-5">
          <SidebarCategoriesList
            categoryData={categoryData}
            loading={categoryLoading}
            catQuery={catQuery}
            setCatQuery={setCatQuery}
          />
        </div>
        <div className="flex-1 bg-white rounded ml-4 pb-4 border border-gray-300">
          <div className="flex border-b border-gray-300">
            <button
              className={`px-4 py-2 m-0 text-base font-bold border-r border-gray-300 ${
                tabVal === "General" ? "text-primary" : ""
              }`}
              onClick={() => handleTabChange("General")}
            >
              General Information
            </button>

            {/* <button
                className={`px-4 py-2 m-0 text-base font-bold border-r ${
                  tabVal === 'Commission' ? 'text-primary' : ''
                } ${generalInfo._id ? '' : 'text-gray-300'}`}
                onClick={() => handleTabChange('Commission')}
                disabled={!generalInfo._id}
              >
                Commission and Access
              </button> */}
          </div>
          {tabVal === "General" && (
            <div className="container px-4">
              <GeneralInfo
                category={categoryData?.data ?? []}
                product_type={productTypeData?.data ?? []}
              />
            </div>
          )}
          {tabVal === "Commission" && (
            <div className="container px-4">
              <div className="w-full mt-4">
                <div className="flex items-end pb-4">
                  <div className="w-1/2">
                    <Input
                      label="Commission"
                      inputid="product-category-default_commission"
                      // value={generalInfo.default_commission}
                      name="default_commission"
                      // onChange={handleChange}
                    />
                  </div>

                  <button className="btn ml-2">Save</button>
                </div>
              </div>
              <div>
                {/* <Table
                    tableData={sellerTable}
                    loading={props.loading}
                    tableHead={[
                      'Company',
                      'Seller',
                      'Email',
                      'Mobile',
                      'Commission',
                      'Default Commission',
                    ]}
                    isSN
                    ispublic
                  /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CategoryManage
