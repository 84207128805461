import { createSlice } from "@reduxjs/toolkit"
import { authApiSlice } from "./authApiSlice"

const routes = JSON.parse(localStorage.getItem("routes"))
const token = localStorage.getItem("token")
const users = JSON.parse(localStorage.getItem("users"))

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: token ? token : null,
    users: users ? users : {},
    one: {
      email: "",
      password: "",
    },
    access: routes?.length
      ? routes
          .map((each) => ({ admin_routes: each.admin_routes[0] }))
          .map((each) => each.admin_routes)
      : [],
    routes: routes?.length ? routes : [],
    notifications: [],
  },
  reducers: {
    setOneValue: (state, action) => {
      state.one[action.payload.key] = action.payload.value
    },
    updateInitialState: (state, action) => {
      state.token = action.payload.token
      state.users = action.payload.data
      state.access = action.payload.data?.routes
        ? action.payload.data?.routes
            .map((each) => ({ admin_routes: each.admin_routes[0] }))
            .map((each) => each.admin_routes)
        : []
      state.routes = action.payload.data.routes
      localStorage.setItem("token", action.payload.token)
      localStorage.setItem("users", JSON.stringify(action.payload.data))
      localStorage.setItem("routes", action.payload.data.routes)
    },
    enqueueSnackbar: (state, action) => {
      state.notifications = [...state.notifications, { ...action.payload }]
    },
    removeSnackbar: (state, action) => {
      state.notifications = state.notifications.filter(
        (each) => each.key !== action.payload,
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApiSlice.endpoints.loginUser.matchFulfilled,
        (state, action) => {
          state.token = action.payload.token
          state.users = action.payload.data
          state.access = action.payload.data?.routes
            ? action.payload.data?.routes
                .map((each) => ({ admin_routes: each.admin_routes[0] }))
                .map((each) => each.admin_routes)
            : []
          state.routes = action.payload.data.routes
          localStorage.setItem("token", action.payload.token)
          localStorage.setItem("users", JSON.stringify(action.payload.data))
          localStorage.setItem(
            "routes",
            JSON.stringify(action.payload.data.routes),
          )
        },
      )
      .addMatcher(authApiSlice.endpoints.logoutUser.matchFulfilled, (state) => {
        state.token = null
        state.users = {}
        state.access = []
      })
      .addMatcher(
        authApiSlice.endpoints.reloadAccess.matchFulfilled,
        (state, action) => {
          state.access = action.payload.data
            ? action.payload.data
                .map((each) => ({ admin_routes: each.admin_routes[0] }))
                .map((each) => each.admin_routes)
            : []
          state.routes = action.payload.data
          localStorage.setItem("routes", JSON.stringify(action.payload.data))
        },
      )
      .addMatcher(
        authApiSlice.endpoints.resetPassword.matchFulfilled,
        (state, action) => {
          state.token = action.payload.token
          state.users = action.payload.data
          state.access = action.payload.data?.routes
            ? action.payload.data?.routes
                .map((each) => ({ admin_routes: each.admin_routes[0] }))
                .map((each) => each.admin_routes)
            : []
          state.routes = action.payload.data.routes
          localStorage.setItem("token", action.payload.token)
          localStorage.setItem("users", JSON.stringify(action.payload.data))
          localStorage.setItem(
            "routes",
            JSON.stringify(action.payload.data.routes),
          )
        },
      )
  },
})

export const {
  setOneValue,
  updateInitialState,
  enqueueSnackbar,
  removeSnackbar,
} = authSlice.actions

export default authSlice.reducer
