/**
 *
 * Breadcrumb
 *
 */

import PropTypes from "prop-types"
import React from "react"
import { FaHome, FaArrowLeft } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom"
import useWindowDimensions from "@utils/windowDimensions"
import "./breadcrumb.css"
import Crumb from "./Crumb"

const Breadcrumb = ({ routeList, linkcomponent, onClick }) => {
  const { pathname } = useLocation()
  const { width } = useWindowDimensions()
  let breadcrumbs = []
  if (routeList.length === 0) {
    const subPaths = pathname.split("/").filter((each) => !!each)
    let tempPathname = pathname
    breadcrumbs = subPaths
      .map(() => {
        const breadcrumb = {
          path: `${tempPathname}`,
          label: tempPathname.substring(tempPathname.lastIndexOf("/") + 1),
        }
        tempPathname = tempPathname.substring(0, tempPathname.lastIndexOf("/"))
        return breadcrumb
      })
      .reverse()
  } else {
    breadcrumbs = routeList
  }

  const LinkComponent = linkcomponent

  if (width < 769) {
    const link = breadcrumbs[breadcrumbs.length - 1]
    const shouldShowGoBack = breadcrumbs.length <= 2
    const previousLink = !shouldShowGoBack
      ? breadcrumbs[breadcrumbs.length - 1]
      : { path: "/" }
    return (
      <div className="flex-1 my-auto">
        <ol className="list-reset inline-flex text-sm items-center">
          <Crumb isLast={false}>
            <LinkComponent
              className="block text-gray-500 no-underline hover:underline cursor-pointer my-auto mr-2"
              to={previousLink.path}
              onClick={onClick}
            >
              <FaArrowLeft className="text-lg" />
            </LinkComponent>
          </Crumb>
          <Crumb isLast>
            <LinkComponent
              className="block text-gray-500 no-underline hover:underline cursor-pointer my-auto"
              to={link.path}
              onClick={() => onClick(link)}
            >
              {link.label}
            </LinkComponent>
          </Crumb>
          {/* {breadcrumbs.map((link, index) => {
            if (link.path === '/admin')
              return (
                <Crumb key={link.path}>
                  <Link
                    className="no-underline hover:text-black my-auto"
                    to="/admin/dashboard/cms"
                  >
                    <HomeIcon />
                  </Link>
                </Crumb>
              );
            if (link.path.endsWith('edit') || link.path.endsWith('access'))
              return null;
            return (
              <Crumb
                key={`${link.path}${index}`}
                isLast={index === breadcrumbs.length - 1}
              >
                <LinkComponent
                  className="text-blue-700 no-underline hover:underline cursor-pointer my-auto"
                  to={link.path}
                  onClick={() => onClick(link)}
                >
                  {link.label}
                </LinkComponent>
              </Crumb>
            );
          })} */}
        </ol>
      </div>
    )
  }
  return (
    <div className="flex-1 my-auto">
      <ol className="list-reset inline-flex text-sm items-center">
        {breadcrumbs.map((link, index) => {
          if (link.path === "/admin")
            return (
              <Crumb key={link.path}>
                <Link
                  className="no-underline hover:text-black my-auto"
                  to="/admin/dashboard/cms"
                >
                  <FaHome />
                </Link>
              </Crumb>
            )
          if (
            (link && link.path && link.path.endsWith("edit")) ||
            (link && link.path && link.path.endsWith("access"))
          )
            return null
          return (
            <Crumb
              key={`${link.path}${index}`}
              isLast={index === breadcrumbs.length - 1}
            >
              <LinkComponent
                className="text-xs block no-underline hover:underline cursor-pointer my-auto capitalize"
                to={link.path}
                onClick={() => onClick(link)}
              >
                {link.label}
              </LinkComponent>
            </Crumb>
          )
        })}
      </ol>
    </div>
  )
}

export default Breadcrumb
