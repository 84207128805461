import EditorFileSelect from "@containers/Admin/EditorFileSelect"
import App from "./App"
import AdminLayout from "./layout"
import { AdminRoutes } from "./routes/admin"

export const Routes = [
  {
    path: "/",
    element: <App />,
  },
  {
    path: "about",
    element: <div>About</div>,
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    children: AdminRoutes.map((each) => ({
      path: `/admin/${each.path}`,
      element: each.element,
    })),
  },
  {
    path: "/editor-file-select",
    element: <EditorFileSelect />,
  },
]
