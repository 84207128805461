import { useAppSelector } from "@app/hooks"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { removeSnackbar } from "@features/auth/authSlice"
import { useDispatch } from "react-redux"

const Notifier = () => {
  const { notifications } = useAppSelector("auth")
  const dispatch = useDispatch()
  useEffect(() => {
    notifications?.map((each, index) => {
      if (each.options && each.options.variant) {
        toast[each.options.variant](each.message, {
          ...each.options,
        })
      } else {
        if (each.options) {
          toast(each.message, { ...each.options })
        } else {
          toast(each.message)
        }
      }
      dispatch(removeSnackbar(each.key))
    })
  }, [notifications])
  return null
}

export default Notifier
