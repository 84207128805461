import React from "react"
import "./styles.css"
import { RiArrowLeftLine } from "react-icons/ri"

const PageHeader = ({ title, handleGoBack, isBack }) => {
  return (
    <div className="flex items-center mb-2" id="page-header">
      {isBack && (
        <span className="backbtn" onClick={handleGoBack}>
          <RiArrowLeftLine className="text-xl" />
        </span>
      )}
      <span className="font-bold text-xl">{title ?? ""}</span>
    </div>
  )
}

export default PageHeader
