import EditorFileSelect from "@containers/Admin/EditorFileSelect/Loadable"

import ModuleManage from "@containers/Admin/Module/Loadable"
import ModuleAddEdit from "@containers/Admin/Module/AddEdit/Loadable"
import ModuleAccessManage from "@containers/Admin/Module/AddEdit/components/Loadable"

import ModuleGroupManage from "@containers/Admin/ModuleGroup/Loadable"
import ModuleGroupAddEdit from "@containers/Admin/ModuleGroup/AddEdit/Loadable"

import CategoryManage from "@containers/CategoryManage"

import AdminDashboard from "@containers/Dashboard"
import RolesManage from "@containers/Admin/Role/Loadable"

import RolesAddEdit from "@containers/Admin/Role/AddEdit/Loadable"
import RoleAccess from "@containers/Admin/Role/Access/Loadable"

import CustomersManage from "@containers/CustomersManage/Loadable"
import UsersAdd from "@containers/CustomersManage/AddEdit/Loadable"

import BlogsCategoryManage from "@containers/Admin/BlogCategoryManage/Loadable"
import BlogsCatAddEdit from "@containers/Admin/BlogCategoryManage/AddEdit/Loadable"

import GlobalSettingsManage from "@containers/Admin/GlobalSettings/Loadable"
import GlobalSettingsAddEdit from "@containers/Admin/GlobalSettings/AddEdit/Loadable"

import BlogManage from "@containers/Admin/BlogManage/Loadable"
import BlogManageAddEdit from "@containers/Admin/BlogManage/AddEdit/Loadable"

import PageManage from "@containers/Admin/Page/Loadable"
import PageManageAddEdit from "@containers/Admin/Page/AddEdit/Loadable"

import PopupManage from "@containers/Admin/PopupManage/Loadable"
import PopupAddEdit from "@containers/Admin/PopupManage/AddEdit/Loadable"

import TestimonialManage from "@containers/Admin/Testimonial/Loadable"
import TestimonialAddEdit from "@containers/Admin/Testimonial/AddEdit/Loadable"

import FaqCatManage from "@containers/Admin/FaqCatManage/Loadable"
import FaqCatAddEdit from "@containers/Admin/FaqCatManage/AddEdit/Loadable"

import FaqManage from "@containers/Admin/FaqManage/Loadable"
import FaqAddEdit from "@containers/Admin/FaqManage/AddEdit/Loadable"

import SocialMediaManage from "@containers/Admin/SocialMedia/Loadable"
import SocialMediaManageAddEdit from "@containers/Admin/SocialMedia/AddEdit/Loadable"

import EmailTemplateManage from "@containers/EmailTemplate/Loadable"
import EmailTemplateAddEdit from "@containers/EmailTemplate/AddEdit/Loadable"

import ContactManage from "@containers/ContactManage/Loadable"
import ContactDetails from "@containers/ContactManage/Details/Loadable"

import ServerErrors from "@containers/Admin/ServerErrors/Loadable"

import SliderSection from "@containers/ContentSections/SliderSection/Loadable"
import SliderSectionAddEdit from "@containers/ContentSections/SliderSection/AddEdit/Loadable"

import PackagaeManage from "@containers/PackageManage/Loadable"
import PackagaeManageAddEdit from "@containers/PackageManage/AddEdit/Loadable"

import ContentSectionManage from "@containers/ContentSections/ContentSection/Loadable"
import ContentSectionAddEdit from "@containers/ContentSections/ContentSection/AddEdit/Loadable"

import MenuSectionManage from "@containers/ContentSections/MenuSection/Loadable"
import MenuSectionAddEdit from "@containers/ContentSections/MenuSection/AddEdit/Loadable"

import BlogGroupManage from "@containers/ContentSections/BlogGroupManage/Loadable"
import BlogGroupAddEdit from "@containers/ContentSections/BlogGroupManage/AddEdit/Loadable"

import OurTeamManage from "@containers/OurTeamManage/Loadable"
import OurTeamManageAddEdit from "@containers/OurTeamManage/AddEdit/Loadable"

import PackageSectionManage from "@containers/ContentSections/PackageSection/Loadable"
import PackageSectionAddEdit from "@containers/ContentSections/PackageSection/AddEdit"

import HomePageGroups from "@containers/HomePageGroups/Loadable"
import HomePageAddEdit from "@containers/HomePageGroups/AddEdit/Loadable"

import MediaSectionManage from "@containers/ContentSections/MediaSection/Loadable"
import MediaSectionMAddEdit from "@containers/ContentSections/MediaSection/AddEdit/Loadable"

import BookingManage from "@containers/BookingManage/Loadable"
import BookingDetailsView from "@containers/BookingManage/View/Loadable"

import BikesManage from "@containers/BikesManage/Loadable"
import BikesManageAddEdit from "@containers/BikesManage/AddEdit/Loadable"

import AccommodationManage from "@containers/AccommodationManage/Loadable"
import AccommodationManageAddEdit from "@containers/AccommodationManage/AddEdit/Loadable"

export const AdminRoutes = [
  {
    path: "dashboard/commerce",
    element: <AdminDashboard />,
  },
  {
    path: "module-manage",
    element: <ModuleManage />,
  },
  {
    path: "module-manage/add",
    element: <ModuleAddEdit />,
  },
  {
    path: "module-manage/edit/:id",
    element: <ModuleAddEdit />,
  },
  {
    path: "module-manage/access/:id",
    element: <ModuleAccessManage />,
  },
  {
    path: "sub-modules",
    element: <ModuleGroupManage />,
  },
  {
    path: "sub-modules/add",
    element: <ModuleGroupAddEdit />,
  },
  {
    path: "sub-modules/edit/:id",
    element: <ModuleGroupAddEdit />,
  },
  {
    path: "product-category-manage",
    element: <CategoryManage />,
  },
  {
    path: "media-manage",
    element: <EditorFileSelect />,
  },
  {
    path: "role-manage",
    element: <RolesManage />,
  },
  {
    path: "role-manage/add",
    element: <RolesAddEdit />,
  },
  {
    path: "role-manage/edit/:id",
    element: <RolesAddEdit />,
  },
  {
    path: "role-manage/access/:id",
    element: <RoleAccess />,
  },
  {
    path: "user-manage",
    element: <CustomersManage />,
  },
  {
    path: "user-manage/add",
    element: <UsersAdd />,
  },
  {
    path: "blog-cat-manage",
    element: <BlogsCategoryManage />,
  },
  {
    path: "blog-cat-manage/add",
    element: <BlogsCatAddEdit />,
  },
  {
    path: "blog-cat-manage/edit/:id",
    element: <BlogsCatAddEdit />,
  },
  {
    path: "global-setting",
    element: <GlobalSettingsManage />,
  },
  {
    path: "global-setting/edit/:id",
    element: <GlobalSettingsAddEdit />,
  },
  {
    path: "global-setting/add",
    element: <GlobalSettingsAddEdit />,
  },
  {
    path: "blog-manage",
    element: <BlogManage />,
  },
  {
    path: "blog-manage/add",
    element: <BlogManageAddEdit />,
  },
  {
    path: "blog-manage/edit/:id",
    element: <BlogManageAddEdit />,
  },
  {
    path: "page-manage",
    element: <PageManage />,
  },
  {
    path: "page-manage/edit/:id",
    element: <PageManageAddEdit />,
  },
  {
    path: "page-manage/add",
    element: <PageManageAddEdit />,
  },
  {
    path: "popup-setting",
    element: <PopupManage />,
  },
  {
    path: "popup-setting/add",
    element: <PopupAddEdit />,
  },
  {
    path: "popup-setting/edit/:id",
    element: <PopupAddEdit />,
  },
  {
    path: "testimonial-manage",
    element: <TestimonialManage />,
  },
  {
    path: "testimonial-manage/add",
    element: <TestimonialAddEdit />,
  },
  {
    path: "testimonial-manage/edit/:id",
    element: <TestimonialAddEdit />,
  },
  {
    path: "faq-category-manage",
    element: <FaqCatManage />,
  },
  {
    path: "faq-category-manage/add",
    element: <FaqCatAddEdit />,
  },
  {
    path: "faq-category-manage/edit/:id",
    element: <FaqCatAddEdit />,
  },
  {
    path: "faq-manage",
    element: <FaqManage />,
  },
  {
    path: "faq-manage/add",
    element: <FaqAddEdit />,
  },
  {
    path: "faq-manage/edit/:id",
    element: <FaqAddEdit />,
  },
  {
    path: "social-media",
    element: <SocialMediaManage />,
  },
  {
    path: "social-media/add",
    element: <SocialMediaManageAddEdit />,
  },
  {
    path: "social-media/edit/:id",
    element: <SocialMediaManageAddEdit />,
  },
  {
    path: "template-manage",
    element: <EmailTemplateManage />,
  },
  {
    path: "template-manage/add",
    element: <EmailTemplateAddEdit />,
  },
  {
    path: "template-manage/edit/:id",
    element: <EmailTemplateAddEdit />,
  },
  {
    path: "contact-manage",
    element: <ContactManage />,
  },
  {
    path: "contact-manage/view/:id",
    element: <ContactDetails />,
  },
  {
    path: "errors",
    element: <ServerErrors />,
  },
  {
    path: "slider-manage",
    element: <SliderSection />,
  },
  {
    path: "slider-manage/add",
    element: <SliderSectionAddEdit />,
  },
  {
    path: "slider-manage/edit/:id",
    element: <SliderSectionAddEdit />,
  },
  {
    path: "packages-manage",
    element: <PackagaeManage />,
  },
  {
    path: "packages-manage/add",
    element: <PackagaeManageAddEdit />,
  },
  {
    path: "packages-manage/edit/:id",
    element: <PackagaeManageAddEdit />,
  },
  {
    path: "content-manage",
    element: <ContentSectionManage />,
  },
  { path: "content-manage/add", element: <ContentSectionAddEdit /> },
  { path: "content-manage/edit/:id", element: <ContentSectionAddEdit /> },
  { path: "menu-manage", element: <MenuSectionManage /> },
  { path: "menu-manage/add", element: <MenuSectionAddEdit /> },
  { path: "menu-manage/edit/:id", element: <MenuSectionAddEdit /> },
  { path: "blog-section-manage", element: <BlogGroupManage /> },
  { path: "blog-section-manage/add", element: <BlogGroupAddEdit /> },
  { path: "blog-section-manage/edit/:id", element: <BlogGroupAddEdit /> },
  { path: "our-team-manage", element: <OurTeamManage /> },
  { path: "our-team-manage/add", element: <OurTeamManageAddEdit /> },
  { path: "our-team-manage/edit/:id", element: <OurTeamManageAddEdit /> },
  { path: "package-section-manage", element: <PackageSectionManage /> },
  { path: "package-section-manage/add", element: <PackageSectionAddEdit /> },
  {
    path: "package-section-manage/edit/:id",
    element: <PackageSectionAddEdit />,
  },
  { path: "home-page-groups", element: <HomePageGroups /> },
  { path: "media-section-manage", element: <MediaSectionManage /> },
  { path: "media-section-manage/edit/:id", element: <MediaSectionMAddEdit /> },
  { path: "media-section-manage/add", element: <MediaSectionMAddEdit /> },
  { path: "homepage-manage", element: <HomePageGroups /> },
  { path: "homepage-manage/add", element: <HomePageAddEdit /> },
  { path: "homepage-manage/edit/:id", element: <HomePageAddEdit /> },
  { path: "booking-manage", element: <BookingManage /> },
  { path: "booking-manage/view/:id", element: <BookingDetailsView /> },
  { path: "bikes-manage", element: <BikesManage /> },
  { path: "bikes-manage/add", element: <BikesManageAddEdit /> },
  { path: "bikes-manage/edit/:id", element: <BikesManageAddEdit /> },
  { path: "accommodation-manage", element: <AccommodationManage /> },
  { path: "accommodation-manage/add", element: <AccommodationManageAddEdit /> },
  {
    path: "accommodation-manage/edit/:id",
    element: <AccommodationManageAddEdit />,
  },
]
