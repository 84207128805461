import Logo from "@assets/img/app_logo.png"
import WhiteLogo from "@assets/img/White Logo.png"
import NoImageFound from "@assets/img/No_Image_Available.jpg"

const mode = import.meta.env.VITE_MODE

export const APP_NAME = "Royal Nepal Motorcycle Tours Pvt. Ltd"
export const APP_LOGO = Logo
export const WHITE_LOGO = WhiteLogo

export const CURRENCY_SIGN = "USD"
export const INTL_NUMBER_FORMAT = "en-IN" // en-US

export const DATE_FORMAT = "YYYY-MM-DD"
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss"

export const COUNTRY_CODE = "+977"

export const PUBLIC_BASE = "https://royalnepalmotorcycletours.com/"
export const IMAGE_BASE =
  mode === "production"
    ? "https://royalnepalmotorcycletours.com/"
    : "http://localhost:4010/"

export const NoImage = NoImageFound

export const errorBuildLvl1 = (errors) => {
  return errors.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.context.key]: curr.message.replace(/[/"_]/g, " "),
    }),
    {},
  )
}
