import { createSelector } from "@reduxjs/toolkit"
import { initialState } from "./packageGroupslice"

const selectDomain = (state) => state.packageGroupManage ?? initialState

export const makeSelectQuery = createSelector(
  selectDomain,
  (state) => state.query,
)

export const makeSelectOne = createSelector(selectDomain, (state) => state.one)

export const makeSelectPackageDropdown = createSelector(
  selectDomain,
  (state) => state.packageDropdown,
)

export const groupTypeOptions = [{ label: "Normal", value: "Normal" }]

export const groupTypeOptNorm = groupTypeOptions.reduce(
  (acc, curr) => ({ ...acc, [curr.value]: curr }),
  {},
)

export const viewTypeOptions = [
  { label: "Normal", value: "normal" },
  { label: "Circular", value: "circular" },
  { label: "Special", value: "special" },
]

export const viewTypeOptNorm = viewTypeOptions.reduce(
  (acc, curr) => ({ ...acc, [curr.value]: curr }),
  {},
)

export const packageOptions = createSelector(
  makeSelectPackageDropdown,
  (substate) =>
    substate ? substate.map((e) => ({ label: e.title, value: e._id })) : [],
)
