import React from "react"
import "./style.css"

const Loading = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-40 z-[999]">
      <div className="loader" />
    </div>
  )
}

export default Loading
