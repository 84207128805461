import { apiSlice } from "@app/api/apiSlice"

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (body) => ({
        url: "user/login",
        method: "POST",
        body,
      }),
    }),
    logoutUser: builder.mutation({
      query: () => ({
        url: "user/logout",
        method: "GET",
      }),
    }),
    reloadAccess: builder.mutation({
      query: () => "user/reload-access",
    }),
    forgetPassword: builder.mutation({
      query: (body) => ({
        url: "user/forgotpassword",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "user/resetpassword",
        method: "POST",
        body,
      }),
    }),
  }),
})

export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useReloadAccessMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
} = authApiSlice
