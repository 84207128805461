import { categoryApiSlice } from "./categoryApiSlice"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  one: {},
  loading: false,
}

export const categoryManageSlice = createSlice({
  name: "categoryManage",
  initialState: initialState,
  reducers: {
    setOneValue: (state, action) => {
      state.one[action.payload.key] = action.payload.value
    },
    clearOne: (state) => {
      state.one = initialState.one
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        categoryApiSlice.endpoints.loadSingleCategory.matchPending,
        (state) => {
          state.loading = true
        },
      )
      .addMatcher(
        categoryApiSlice.endpoints.loadSingleCategory.matchFulfilled,
        (state, action) => {
          state.one = action.payload.data
          state.loading = false
        },
      )
      .addMatcher(
        categoryApiSlice.endpoints.loadSingleCategory.matchRejected,
        (state) => {
          state.one = initialState.one
          state.loading = false
        },
      )
  },
})

export const { setOneValue, clearOne } = categoryManageSlice.actions

export default categoryManageSlice.reducer
