import { editorFileSelectApiSlice } from "./editorFileSelectApiSlice"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  chosen_files: [],
  one: {
    name: "",
  },
  folderName: {
    open: false,
    name: "",
    loading: false,
  },
}

export const editorFlieSelectSlice = createSlice({
  name: "editorFileSelect",
  initialState,
  reducers: {
    setOneValue: (state, action) => {
      state.one[action.payload.key] = action.payload.value
    },
    setFolderValue: (state, action) => {
      state.folderName[action.payload.key] = action.payload.value
    },
    setFolderClose: (state, action) => {
      state.folderName = {
        ...state.folderName,
        open: false,
        name: "",
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      editorFileSelectApiSlice.endpoints.saveNewFolder.matchFulfilled,
      (state, action) => {
        state.folderName.open = false
        state.folderName.name = ""
      },
    )
  },
})

export const { setOneValue, setFolderValue, setFolderClose } =
  editorFlieSelectSlice.actions

export default editorFlieSelectSlice.reducer
