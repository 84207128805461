import { apiSlice } from "@app/api/apiSlice"

export const packageGroupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadAllPackageGroup: builder.mutation({
      query: (query) => `package-group?${query}`,
    }),
    loadOnePackageGroup: builder.mutation({
      query: (id) => `package-group/${id}`,
    }),
    saveOnePackageGroup: builder.mutation({
      query: (body) => ({
        url: "package-group",
        method: "POST",
        body,
      }),
    }),
    deleteOnePackageGroup: builder.mutation({
      query: (id) => ({
        url: `package-group/${id}`,
        method: "DELETE",
      }),
    }),
    loadPackageDropdown: builder.query({
      query: () => ({
        url: `packages/active/dropdown`,
        method: "GET",
      }),
    }),
  }),
})

export const {
  useLoadAllPackageGroupMutation,
  useSaveOnePackageGroupMutation,
  useLoadOnePackageGroupMutation,
  useDeleteOnePackageGroupMutation,
  useLoadPackageDropdownQuery,
} = packageGroupApiSlice
