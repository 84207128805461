import React from "react"

const PageContent = ({ children, loading }) => {
  return (
    <div className={`${loading ? "opacity-40" : ""} mb-5 shadow-md bg-white`}>
      {children}
    </div>
  )
}

export default PageContent
