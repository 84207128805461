import React from "react"
// import TopInfographic from "./components/TopInfographic"
// import OrderInfographics from "./components/OrderInfographics"
// import OrderByDate from "./components/OrderByDate"
// import OrderWise from "./components/OrderWise"
// import OrderPaymentDataByAmount from "./components/OrderPaymentDataByAmount"
// import OrderPaymentDataByQuantity from "./components/OrderPaymentDataByQuantity"
// import RecentCustomer from "./components/RecentCustomer"
// import UserDataByDate from "./components/UserDataByDate"
// import UserInfoGraphics from "./components/UserInfoGraphics"
import { Helmet } from "react-helmet"
import { APP_NAME } from "@utils/constants"
import { useInjectReducer } from "@app/store"
import dashboardSlice from "@features/dashboard/dashboardSlice"

const AdminDashboard = () => {
  useInjectReducer("dashboard", dashboardSlice)
  return (
    <>
      <Helmet>
        <title>{`Admin : ${APP_NAME}`}</title>
      </Helmet>
      {/* <TopInfographic /> */}
      {/* <OrderInfographics /> */}
      {/* <OrderByDate /> */}
      {/* <OrderWise /> */}
      <div className="grid grid-cols-7 gap-4 mb-4">
        <div className="col-span-5 grid grid-cols-2 gap-4 text-xs">
          {/* <OrderPaymentDataByAmount /> */}
          {/* <OrderPaymentDataByQuantity /> */}
        </div>
        {/* <RecentCustomer /> */}
      </div>

      <div className="grid grid-cols-7 gap-4 mb-4 text-xs">
        {/* <UserDataByDate /> */}
        {/* <UserInfoGraphics /> */}
      </div>
    </>
  )
}

export default AdminDashboard
