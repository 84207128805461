import React from "react"
import Select from "react-select"
import { customStyles } from "./customStyles"
import { CustomOption } from "@components/SelectCustomStyles"

const SelectWrapper = ({
  divClassName,
  className,
  options,
  onChange,
  error,
  labelClassName,
  name,
  styles,
  label,
  value,
  ...restProps
}) => {
  return (
    <div className={`flex flex-col ${divClassName ?? ""}`}>
      {label && (
        <label
          htmlFor={name}
          className={`${
            labelClassName ? labelClassName : "font-semibold text-sm"
          }`}
        >
          {label}
        </label>
      )}
      <Select
        options={options ?? []}
        onChange={onChange}
        name={name}
        id={name}
        className={className}
        styles={styles ?? customStyles}
        value={value}
        {...restProps}
      />
      {error && <div className="text-xs text-danger">{error}</div>}
    </div>
  )
}

export default SelectWrapper

export const VirtualizedSelect = ({
  divClassName,
  className,
  options,
  onChange,
  error,
  labelClassName,
  name,
  styles,
  label,
  value,
  ...restProps
}) => {
  // const customFilter = createFilter({ ignoreAccents: false })
  const customComponents = {
    Option: CustomOption,
  }

  return (
    <div className={`flex flex-col ${divClassName ?? ""}`}>
      {label && (
        <label
          htmlFor={name}
          className={`${
            labelClassName ? labelClassName : "font-semibold text-sm"
          }`}
        >
          {label}
        </label>
      )}
      <Select
        maxMenuHeight={200}
        components={customComponents}
        // filterOption={customFilter}
        options={options}
        value={value}
        onChange={onChange}
        styles={{
          // please do not add menulist styles this will break the component
          option: (provided, state) => ({
            ...provided,
            background:
              state.isFocused || state.isSelected ? "#5897FB" : "white",
            color: state.isFocused || state.isSelected ? "white" : "black",
            fontweight: "normal",
            display: "block",
            whitespace: "pre",
            fontSize: "12px",
            height: 22,
          }),
          menu: (provided) => ({
            ...provided,
            margin: 0,
            zIndex: 999,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#8e8e8e",
            fontSize: "12px",
          }),
          control: (base, state) => ({
            display: "flex",
            justifyContent: "space-between",
            border: state.isFocused ? "1px solid #00A6E9" : "1px solid #dfdfdf",
            borderRadius: "4px",
            background: "#FFFFFF",
            height: 40,
          }),
          indicatorSeparator: () => ({
            background: "transparent",
          }),
          container: (provided) => ({
            ...provided,
            width: "100%",
          }),
        }}
        {...restProps}
      />
      {error && <div className="text-xs text-danger">{error}</div>}
    </div>
  )
}
