/**
 *
 * DropdownMenu
 *
 */

import React, { useEffect, useRef, useState } from "react"

function useComponentVisible(initialIsVisible, setShowList) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    // uses ref to check if outside of Div is clicked
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false)
      if (setShowList === undefined) {
        console.log("!! onClose function not passed to dialog component. !!")
      } else {
        setShowList(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  })

  return { ref, isComponentVisible, setIsComponentVisible }
}

const DropdownMenu = ({ main, items, isLeft }) => {
  const [open, setOpen] = useState(false)

  const handleMenu = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(open, handleClose)

  return (
    <>
      <div onClick={() => handleMenu()}>
        <div className="relative">
          <div className="leading-none">{main}</div>
          {open ? (
            <>
              <div
                className="w-screen h-screen z-40 fixed top-0 left-0 overflow-auto"
                onClick={handleClose}
              />

              <div
                className={`z-50 shadow absolute w-56 bg-white border border-gray-400 mt-1 
                ${`${isLeft ? "left-0" : "right-0"}`}`}
                onClick={() => handleMenu()}
              >
                {items && items}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default DropdownMenu
