import { useSelector } from "react-redux"

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppSelector = (key) => {
  const selectedState = useSelector((state) => state[key])
  if (!selectedState) {
    throw new Error("Please check your store. Required state not found.")
  }
  return selectedState
}
