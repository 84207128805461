import { createSlice } from "@reduxjs/toolkit"
import { dashboardApiSlice } from "./dashboardApiSlice"

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApiSlice.endpoints.loadAdminNumbers.matchFulfilled,
      (state, action) => {
        state.numbers = action.payload.data
      },
    )
  },
})

export const {} = dashboardSlice.actions

export default dashboardSlice.reducer
