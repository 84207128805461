import { apiSlice } from "@app/api/apiSlice"

export const editorFileSelectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadFile: builder.mutation({
      query: (query) =>
        `files/folder/${query.path}?is_seller=${query.is_seller}&${query.queryObj}`,
    }),
    saveNewFolder: builder.mutation({
      query: ({ is_seller, key, ...body }) => ({
        url: `files/folder/${key}?is_seller=${is_seller}`,
        method: "POST",
        body,
      }),
    }),
    addMedia: builder.mutation({
      query: ({ folder_id, is_seller, formData }) => ({
        url: `files/file/${folder_id}?is_seller=${is_seller}`,
        method: "POST",
        body: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    renameFile: builder.mutation({
      query: ({ renamed_name, _id }) => ({
        url: `files/rename/file`,
        method: "POST",
        body: { renamed_name, _id },
      }),
    }),
    deleteMedia: builder.mutation({
      query: ({ folder_id, file_id }) => ({
        url: "files/deleteall/files/folder",
        method: "POST",
        body: { folder_id, file_id },
      }),
    }),
  }),
})

export const {
  useLoadFileMutation,
  useSaveNewFolderMutation,
  useAddMediaMutation,
  useRenameFileMutation,
  useDeleteMediaMutation,
} = editorFileSelectApiSlice
