import { useAppSelector } from "@app/hooks"
import ColoredScrollbars from "@components/ColoredScrollbars"
import DropdownMenu from "@components/DropdownMenu"
import Loading from "@components/Loading/Loading"
import {
  useLogoutUserMutation,
  useReloadAccessMutation,
} from "@features/auth/authApiSlice"
import { updateInitialState } from "@features/auth/authSlice"
import { APP_NAME, PUBLIC_BASE, WHITE_LOGO } from "@utils/constants"
import jwtDecode from "jwt-decode"
import { useEffect, useState } from "react"
import { FaAngleDown } from "react-icons/fa"
import {
  RiArrowDownSLine,
  RiExternalLinkLine,
  RiRefreshLine,
  RiUserLine,
} from "react-icons/ri"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import menu from "./adminLinks"

const AdminLayout = () => {
  const { access } = useAppSelector("auth")
  const { pathname } = useLocation()
  const { users, token } = useAppSelector("auth")
  const navigate = useNavigate()

  const [logoutUser, { isLoading, isError }] = useLogoutUserMutation()
  const [reloadAccess, { isLoading: reloadLoading, isError: reloadError }] =
    useReloadAccessMutation()

  const [anchorel, setAnchorel] = useState(null)
  const [openSet, setOpenSet] = useState({})
  const [menuListing, setMenuListing] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [searchPath, setSearchPath] = useState("")

  const singleLevelArray = (menu) => {
    let tempMenus = []
    for (let index = 0; index < menu.length; index++) {
      const element = menu[index]
      const item = {
        name: element.name,
        value: element.key,
        link: element.link,
        icon: element.icon,
      }
      if (element.menu && element.menu.length > 0) {
        const tempMenusNew = singleLevelArray(element.menu)
        tempMenus = [...tempMenus, ...tempMenusNew]
      } else {
        tempMenus = [...tempMenus, item]
      }
    }
    tempMenus = tempMenus.filter((e) => {
      if (hasAccess(e.link)) {
        return true
      } else {
        return false
      }
    })
    return tempMenus
  }

  const handleSetClick = (key) => {
    setOpenSet({ ...openSet, [key]: !openSet[key] })
    setInitialLoad(false)
  }

  const hasAccess = (link) => access.includes(link)

  const [initialLoad, setInitialLoad] = useState(true)

  const checkOpenRoute = (menu) => {
    let splitPath = []
    if (initialLoad && searchPath !== "") {
      if (searchPath.includes(menu.link)) {
        splitPath = menu.key.split(".")
        // TODO make conditions dynamic
        if (splitPath.length === 2) {
          setOpenSet({ ...openSet, [splitPath[0]]: !openSet[splitPath[0]] })
          setInitialLoad(false)
        } else if (splitPath.length === 3) {
          setOpenSet({
            ...openSet,
            [splitPath[0]]: !openSet[splitPath[0]],
            [`${splitPath[0]}.${splitPath[1]}`]:
              !openSet[`${splitPath[0]}.${splitPath[1]}`],
          })
          setInitialLoad(false)
        }
      }
    }
    if (initialLoad) {
      if (pathname.includes(menu.link)) {
        splitPath = menu.key.split(".")
        // TODO make conditions dynamic
        if (splitPath.length === 2) {
          setOpenSet({ ...openSet, [splitPath[0]]: !openSet[splitPath[0]] })
          setInitialLoad(false)
        } else if (splitPath.length === 3) {
          setOpenSet({
            ...openSet,
            [splitPath[0]]: !openSet[splitPath[0]],
            [`${splitPath[0]}.${splitPath[1]}`]:
              !openSet[`${splitPath[0]}.${splitPath[1]}`],
          })
          setInitialLoad(false)
        }
      }
    }
  }

  const handleSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleSearchClick = (event) => {
    setSearchTerm("")
    setSearchPath(event.link)
    setInitialLoad(true)
    setTimeout(checkOpenRoute(event), 2000)
  }

  const handleClose = () => {
    setAnchorel(null)
  }

  const handleLogout = () => {
    logoutUser()
      .unwrap()
      .then((res) => {
        if (res?.success) {
          navigate("/")
          localStorage.clear()
        }
      })
  }

  const menuFunction = (e) => {
    let showChildren = false
    if (e.menu) {
      // TODO: can be optimized to break when if condition is fulfilled
      e.menu.map((each) => {
        if (hasAccess(each.link)) {
          showChildren = true
        }
      })
    }
    const isVisible = e.menu ? showChildren : hasAccess(e.link)
    if (!isVisible) return null
    return (
      <div key={e.key}>
        {e.menu ? (
          <>
            <div
              key={e.key}
              className={`py-2 cursor-pointer flex items-center justify-between hover:bg-black text-xs pl-${
                e.key.split(".").length * 4
              }`}
              onClick={() => handleSetClick(e.key)}
            >
              <div className="flex items-center">
                <span className="inline-block text-gray-200">{e.icon}</span>
                <span className="dropdown-title text-gray-200 pl-4">
                  {e.name}
                </span>
              </div>
              <FaAngleDown
                className={`text-xs text-gray-200 mr-4 ${
                  openSet[e.key] === false || openSet[e.key] == undefined
                    ? "-rotate-90"
                    : ""
                }`}
              />
            </div>
            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out ${
                openSet[e.key] === false || openSet[e.key] == undefined
                  ? "max-h-0"
                  : "max-h-screen"
              }`}
            >
              {e.menu.map((el) => (
                <div key={el.key}>{menuFunction(el)}</div>
              ))}
            </div>
          </>
        ) : (
          <div
            selected={pathname === e.link}
            className={e.key.split(".").length === 1 ? "" : ""}
            onClick={checkOpenRoute(e)}
          >
            <Link
              to={`${e.link}`}
              className={`text-gray-200 text-xs no-underline flex items-center hover:bg-black py-2 pl-${
                e.key.split(".").length * 4
              } ${pathname.includes(e.link) ? "active" : ""}`}
            >
              <span className="inline-block">{e.icon}</span>
              <span className="pl-4">{e.name}</span>
            </Link>
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    const newMenu = singleLevelArray(menu)
    setMenuListing(newMenu)
  }, [menu])

  useEffect(() => {
    if (!token) {
      navigate("/")
    } else if (token) {
      const decoded = jwtDecode(token)
      if (
        !(
          typeof decoded === "object" &&
          typeof decoded.exp === "number" &&
          decoded.exp > Date.now() / 1000
        )
      ) {
        localStorage.clear()
      } else {
        const routes = JSON.parse(localStorage.getItem("routes"))
        const user = {
          id: decoded.id,
          name: decoded.name,
          avatar: decoded.avatar,
          email: decoded.email,
          roles: decoded.roles,
          email_verified: decoded.email_verified,
          routes: routes ?? [],
        }
        updateInitialState({ token: token, data: user })
      }
    }
  }, [token])

  if (!token) {
    return null
  }

  return (
    <>
      {reloadLoading ? <Loading /> : null}
      <div className="relative text-xs">
        <div className="flex justify-between px-4 text-white h-16 fixed z-[1000] w-full left-0 top-0 items-center bg-gray-800">
          <img
            src={WHITE_LOGO}
            alt={APP_NAME}
            className="object-contain w-40 h-28 py-1"
          />
          <div className="flex gap-4 items-center">
            <button
              className="text-2xl bg-info p-2 rounded-full"
              title="Refresh Access"
              onClick={reloadAccess}
            >
              <RiRefreshLine />
            </button>
            <a
              href={PUBLIC_BASE}
              target="_blank"
              className="whitespace-nowrap border-white py-1 px-2 text-xs flex items-center gap-2"
            >
              Visit Site
              <RiExternalLinkLine />
            </a>
            <DropdownMenu
              main={
                <button>
                  <div className="relative text-sm flex topql items-center whitespace-nowrap text-white hover:text-info">
                    <RiUserLine className="text-lg font-bold" />
                    <div className="inline-block ml-2 text-sm mr-1">
                      {users.name}
                    </div>
                    <RiArrowDownSLine className="text-xl" />
                  </div>
                </button>
              }
              items={
                <>
                  {users.isAdmin && (
                    <Link
                      to="/admin/dashboard/commerce"
                      onClick={handleClose}
                      className="text-black py-2 block px-4 hover:bg-gray-100 cursor-pointer border-b border-gray-100"
                    >
                      <p>Admin Dashboard</p>
                    </Link>
                  )}
                  {users.isSeller && (
                    <Link
                      to="/seller/dashboard"
                      onClick={handleClose}
                      className="text-black py-2 block px-4 hover:bg-gray-100 cursor-pointer border-b border-gray-100"
                    >
                      <p>Seller Dashboard</p>
                    </Link>
                  )}
                  {/* <Link
                    to={{ pathname: `${PUBLIC_BASE}/user/profile/info` }}
                    onClick={handleClose}
                    className="text-black py-2 block px-4 hover:bg-gray-100 cursor-pointer border-b border-gray-100"
                    target="_blank"
                  >
                    <p>Profile</p>
                  </Link> */}
                  <p
                    className="text-black py-2 block px-4 hover:bg-gray-100 cursor-pointer"
                    onClick={handleLogout}
                  >
                    Log Out
                  </p>
                </>
              }
            />
          </div>
        </div>
        <div className="flex overflow-y-hidden">
          <ColoredScrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{
              width: 256,
              height: "100vh",
              background: "linear-gradient(180deg, #0D0E23 0%, #0f5f66 100%)",
            }}
          >
            <div className="dash_sidemenu select-none pt-16 pb-8">
              <div className="p-4">
                <input
                  onChange={handleSearchTerm}
                  placeholder="Search Keyword..."
                  className="inputbox text-sm w-full"
                  value={searchTerm}
                  style={{
                    background: "#1f2937",
                    borderColor: "#1f2937",
                    color: "#FFFFFF",
                  }}
                  autoComplete="new-password"
                />
              </div>
              <div className="">
                {searchTerm === "" ? (
                  <>
                    {menu.map((e) => (
                      <div key={e.key}>{menuFunction(e)}</div>
                    ))}
                  </>
                ) : (
                  menuListing
                    .filter((each) =>
                      each.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()),
                    )
                    .map((eachMenu) => (
                      <div key={eachMenu.key}>
                        <Link
                          to={`${eachMenu.link}`}
                          className={`px-4 text-white text-xs no-underline flex items-center py-2 hover:bg-black ${
                            pathname.includes(eachMenu.link) ? "active" : ""
                          }`}
                          onClick={() => handleSearchClick(eachMenu)}
                        >
                          <span className="inline-block">{eachMenu.icon}</span>
                          <span className="pl-4">{eachMenu.name}</span>
                        </Link>
                      </div>
                    ))
                )}
              </div>
            </div>
          </ColoredScrollbars>
          <div className="h-screen flex-1 overflow-auto px-4 py-20 bg-[#f2f3f7]">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminLayout
