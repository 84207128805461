export const queryHelper = (queryobj) => {
  let query = ""
  if (queryobj) {
    Object.keys(queryobj).map((each, index) => {
      if (index === 0) {
        query = `${each}=${queryobj[each]}`
      } else {
        query = `${query}&${each}=${queryobj[each]}`
      }
      return null
    })
  }
  return query
}
