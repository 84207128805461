import React from "react"
import "./style.css"

const Button = ({
  loading,
  disabled,
  onClick,
  loadingTitle,
  title,
  type,
  className,
  fullWidth,
  isAdmin,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      className={`${
        className
          ? className
          : isAdmin
          ? "text-sm bg-info text-white h-[37px] mt-[9px] hover:shadow-md duration-200 w-full"
          : "bg-primary text-white rounded-md py-2 px-3 disabled:bg-gray-400 text-sm disabled:cursor-not-allowed"
      } ${fullWidth ? "w-full" : ""}`}
    >
      {loading ? (
        <div className="flex items-center gap-2 justify-center">
          <span>{loadingTitle ?? "Saving Changes"}</span>
          <span className="btn-loader"></span>
        </div>
      ) : (
        <span>{title ?? "Save Changes"}</span>
      )}
    </button>
  )
}

export default Button
