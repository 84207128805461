import { createSlice } from "@reduxjs/toolkit"
import { packageGroupApiSlice } from "./packageGroupApiSlice"

export const initialState = {
  query: {},
  one: {
    group_name: "",
    group_key: "",
    view_type: "normal",
    group_type: "Normal",
    package: [],
    is_active: "",
  },
  packageDropdown: [],
}

const packageGroupSlice = createSlice({
  name: "packageGroupManage",
  initialState,
  reducers: {
    setQueryValue: (state, action) => {
      if (action.payload.key) {
        state.query[action.payload.key] = action.payload.value
      } else {
        state.query = action.payload
      }
    },
    clearOne: (state, action) => {
      state.one = initialState.one
    },
    setOneValue: (state, action) => {
      state.one[action.payload.key] = action.payload.value
    },
    addPackage: (state, action) => {
      const tempPackageObj = state.packageDropdown.find(
        (e) => e._id == action.payload,
      )
      state.one.package = [...state.one.package, tempPackageObj]
    },
    removePackage: (state, action) => {
      state.one.package = state.one.package.filter(
        (e, idx) => idx !== action.payload,
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        packageGroupApiSlice.endpoints.loadOnePackageGroup.matchFulfilled,
        (state, action) => {
          state.one = action.payload.data[0] ?? initialState.one
        },
      )
      .addMatcher(
        packageGroupApiSlice.endpoints.loadPackageDropdown.matchFulfilled,
        (state, action) => {
          state.packageDropdown = action.payload.data
        },
      )
  },
})

export const {
  setOneValue,
  clearOne,
  setQueryValue,
  addPackage,
  removePackage,
} = packageGroupSlice.actions

export default packageGroupSlice.reducer
