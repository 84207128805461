import {
  FaAcquisitionsIncorporated,
  FaAddressBook,
  FaBars,
  FaBoxes,
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaClipboardList,
  FaCogs,
  FaEnvelopeOpenText,
  FaExclamationCircle,
  FaExpandArrowsAlt,
  FaFile,
  FaHome,
  FaImages,
  FaMinus,
  FaMotorcycle,
  FaNewspaper,
  FaQuestionCircle,
  FaQuoteLeft,
  FaShapes,
  FaShareSquare,
  FaShoppingCart,
  FaSlidersH,
  FaTachometerAlt,
  FaTools,
  FaUsers,
  FaUsersCog,
  FaUserShield,
  FaVideo,
} from "react-icons/fa"

// last digit used = 19;
// please use number above this digit and use it serially

const menu = [
  // {
  //   key: "1",
  //   name: "Dashboard",
  //   icon: <FaTachometerAlt />,
  //   link: "/admin/dashboard/commerce",
  // },
  {
    key: "8",
    name: "Booking Manage",
    icon: <FaShoppingCart />,
    link: "/admin/booking-manage",
  },
  {
    key: "4",
    name: "Home Page",
    icon: <FaShapes />,
    link: "/admin/homepage-manage",
  },
  {
    key: "7",
    name: "Packages",
    icon: <FaBoxes />,
    link: "/admin/packages-manage",
  },
  {
    key: "9",
    name: "Bikes",
    icon: <FaMotorcycle />,
    link: "/admin/bikes-manage",
  },
  {
    key: "10",
    name: "Accommodation",
    icon: <FaClipboardList />,
    link: "/admin/accommodation-manage",
  },
  {
    key: "5",
    name: "Content Sections",
    icon: <FaHome />,
    menu: [
      {
        key: "5.1",
        name: "Content Section",
        link: "/admin/content-manage",
        icon: <FaFile />,
      },
      {
        key: "5.2",
        name: "Menu Section",
        icon: <FaBars />,
        link: "/admin/menu-manage",
      },
      {
        key: "5.3",
        name: "Slider Section",
        icon: <FaSlidersH />,
        link: "/admin/slider-manage",
      },
      {
        key: "5.4",
        name: "Blog Section",
        link: "/admin/blog-section-manage",
        icon: <FaNewspaper />,
      },
      {
        key: "5.6",
        name: "Package Section",
        link: "/admin/package-section-manage",
        icon: <FaBoxes />,
      },
      {
        key: "5.7",
        name: "Media Section",
        link: "/admin/media-section-manage",
        icon: <FaVideo />,
      },
    ],
  },
  {
    key: "6",
    name: "Users",
    icon: <FaUsers />,
    link: "/admin/user-manage",
  },
  {
    key: "12",
    name: "E-Reports",
    icon: <FaChartLine />,
    menu: [
      {
        key: "12.1",
        name: "Sales Report",
        icon: <FaChartBar />,
        link: "/admin/sales-report",
      },
      {
        key: "12.2",
        name: "Product Sales Report",
        icon: <FaChartBar />,
        link: "/admin/item-sales-report",
      },
      {
        key: "12.3",
        name: "Stock Report",
        icon: <FaChartBar />,
        link: "/admin/stock-report",
      },
      {
        key: "12.4",
        name: "Top Selling Report",
        icon: <FaChartBar />,
        link: "/admin/top-selling-product-report",
      },
      {
        key: "12.5",
        name: "Product Never Purchased Report ",
        icon: <FaChartBar />,
        link: "/admin/product-never-purchased-report",
      },
      {
        key: "12.6",
        name: "Seller Summary",
        icon: <FaChartBar />,
        link: "/admin/report/seller-summary",
      },
      {
        key: "12.7",
        name: "Coupon Report",
        icon: <FaChartBar />,
        link: "/admin/coupon-report",
      },
      {
        key: "12.8",
        name: "Delivered Report",
        icon: <FaChartBar />,
        link: "/admin/delivered-report",
      },
      {
        key: "12.9",
        name: "Returned Report",
        icon: <FaChartBar />,
        link: "/admin/returned-report",
      },
    ],
  },
  {
    key: "2",
    name: "Media",
    icon: <FaImages />,
    link: "/admin/media-manage",
  },
  {
    key: "3",
    name: "Our Team",
    icon: <FaUsers />,
    link: "/admin/our-team-manage",
  },
  {
    key: "14",
    name: "Content",
    icon: <FaAcquisitionsIncorporated />,
    menu: [
      {
        key: "14.1",
        name: "Page",
        icon: <FaMinus />,
        link: "/admin/page-manage",
      },

      // {
      //   key: "14.4",
      //   name: "Popup",
      //   icon: <FaExpandArrowsAlt />,
      //   link: "/admin/popup-setting",
      // },
      {
        key: "14.5",
        name: "Blog",
        icon: <FaNewspaper />,
        menu: [
          {
            key: "14.5.1",
            name: "Blog",
            link: "/admin/blog-manage",
            icon: <FaMinus />,
          },
          {
            key: "14.5.2",
            name: "Blog Category",
            icon: <FaMinus />,
            link: "/admin/blog-cat-manage",
          },
        ],
      },
      {
        key: "14.6",
        name: "FAQ",
        icon: <FaQuestionCircle />,
        menu: [
          {
            key: "14.6.1",
            name: "Faq",
            icon: <FaMinus />,
            link: "/admin/faq-manage",
          },
          {
            key: "14.6.2",
            name: "Category",
            icon: <FaMinus />,
            link: "/admin/faq-category-manage",
          },
        ],
      },
      {
        key: "14.7",
        name: "Social Media",
        icon: <FaShareSquare />,
        link: "/admin/social-media",
      },
      {
        key: "14.8",
        name: "Testimonial",
        icon: <FaQuoteLeft />,
        link: "/admin/testimonial-manage",
      },
    ],
  },
  {
    key: "16",
    name: "Roles & Access",
    icon: <FaUserShield />,
    menu: [
      {
        key: "16.1",
        name: "Roles",
        icon: <FaUsersCog />,
        link: "/admin/role-manage",
      },
      {
        key: "16.3",
        name: "Access Modules",
        icon: <FaMinus />,
        link: "/admin/module-manage",
      },
      {
        key: "16.4",
        name: "Access Module Group",
        icon: <FaMinus />,
        link: "/admin/sub-modules",
      },
    ],
  },
  {
    key: "18",
    name: "Settings",
    icon: <FaCogs />,
    menu: [
      {
        key: "18.1",
        name: "Email Template",
        icon: <FaEnvelopeOpenText />,
        link: "/admin/template-manage",
      },

      // {
      //   key: "18.2",
      //   name: "Global Settings",
      //   icon: <FaTools />,
      //   link: "/admin/global-setting",
      // },
    ],
  },
  {
    key: "19",
    name: "Reports",
    icon: <FaChartArea />,
    menu: [
      {
        key: "19.1",
        name: "Contacts",
        icon: <FaAddressBook />,
        link: "/admin/contact-manage",
      },
      // {
      //   key: "19.3",
      //   name: "Server Errors",
      //   icon: <FaExclamationCircle />,
      //   link: "/admin/errors",
      // },
      // {
      //   key: "19.4",
      //   name: "Client Errors",
      //   icon: <FaExclamationCircle />,
      //   link: "/admin/errors-client",
      // },
    ],
  },
]
export default menu
