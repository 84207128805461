import { useAppSelector } from "@app/hooks"
import Button from "@components/Button"
import Input, { PasswordInput } from "@components/Input"
import {
  useForgetPasswordMutation,
  useLoginUserMutation,
  useResetPasswordMutation,
} from "@features/auth/authApiSlice"
import { setOneValue } from "@features/auth/authSlice"
import { APP_LOGO, APP_NAME } from "@utils/constants"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { enqueueSnackbar } from "@features/auth/authSlice"
import LoginImg from "@assets/img/Logo.png"
import Dialog from "@components/Dialog"
import Joi from "joi"

const emailSchema = Joi.object({
  email: Joi.string().required().label("Email Address"),
})

const resetSchema = Joi.object({
  code: Joi.any().required().label("OTP"),
  password: Joi.string().required().label("Password"),
})

function App() {
  const { one, token } = useAppSelector("auth")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // rtk part
  const [loginUser, { isLoading, isError, error }] = useLoginUserMutation()
  const [
    sendOtp,
    { isLoading: sendingOtp, isError: otpError, error: otpErrorObj },
  ] = useForgetPasswordMutation()
  const [
    resetPassword,
    { isLoading: resetLoading, isError: restError, error: resetErrorObj },
  ] = useResetPasswordMutation()

  const [state, setState] = useState({
    open: false,
    email: "",
    code: "",
    password: "",
    isOtp: false,
  })
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (token) {
      navigate("/admin/booking-manage")
    }
  }, [token])

  const handleChange = (name) => (e) => {
    dispatch(setOneValue({ key: name, value: e.target.value }))
  }

  const handleLogin = () => {
    loginUser(one)
      .unwrap()
      .then((res) => {
        if (res.success) {
          navigate("/admin/booking-manage")
        }
      })
      .catch((err) => {
        if (!err?.data?.success) {
          dispatch(
            enqueueSnackbar({
              message: err?.data?.msg,
              options: {
                variant: "warning",
              },
            }),
          )
        }
      })
  }

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      handleLogin()
    }
  }

  const handleClose = () => {
    setState({ open: false, code: "", email: "" })
  }

  const handleOpen = () => {
    setState({ open: true, code: "", email: "" })
  }

  const handleChangeState = (name) => (e) => {
    setState({ ...state, [name]: e.target.value })
  }

  const handleSendOtp = () => {
    const { error } = emailSchema.validate(
      {
        email: state.email,
      },
      { abortEarly: false },
    )
    if (error) {
      const errorMsg = errorBuildLvl1(error.details)
      setErrors(errorMsg)
    } else {
      sendOtp({
        email: state.email,
      })
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setState({
              ...state,
              isOtp: true,
              password: "",
              code: "",
            })
            dispatch(
              enqueueSnackbar({
                message: res?.msg,
                options: {
                  variant: "success",
                },
              }),
            )
          } else {
            dispatch(
              enqueueSnackbar({
                message: res?.msg,
                options: {
                  variant: "warning",
                },
              }),
            )
          }
        })
        .catch((err) => {
          console.log(err)
          dispatch(
            enqueueSnackbar({
              message: err?.data?.msg ?? "Password reset failed.",
              options: {
                variant: "warning",
              },
            }),
          )
        })
    }
  }

  const handleReset = () => {
    const { error } = resetSchema.validate(
      {
        code: state.code,
        password: state.password,
      },
      { abortEarly: false },
    )
    if (error) {
      const errorMsg = errorBuildLvl1(error.details)
      setErrors(errorMsg)
    } else {
      resetPassword({
        code: state.code,
        password: state.password,
        confirm_password: state.password,
        email: state.email,
      })
        .unwrap()
        .then((res) => {
          if (res?.success) {
            setState({
              isOtp: false,
              password: "",
              code: "",
              email: "",
              open: false,
            })
            dispatch(
              enqueueSnackbar({
                message: res?.msg,
                options: {
                  variant: "success",
                },
              }),
            )
            navigate("/admin/booking-manage")
          } else {
            dispatch(
              enqueueSnackbar({
                message: res?.msg,
                options: {
                  variant: "warning",
                },
              }),
            )
          }
        })
        .catch((err) => {
          console.log(err)
          dispatch(
            enqueueSnackbar({
              message: err?.data?.msg ?? "Password reset failed.",
              options: {
                variant: "warning",
              },
            }),
          )
        })
    }
  }

  if (token) {
    return null
  }

  return (
    <>
      <Dialog
        open={state.open ?? false}
        onClose={handleClose}
        title="Reset Password"
        body={
          <div>
            <div className="font-semibold text-base mb-4">
              Enter your email address to reset your password.
            </div>
            <Input
              label="Email Address"
              value={state.email}
              id="email"
              onChange={handleChangeState("email")}
              divClassName="w-full mb-4"
            />
            {state.isOtp ? (
              <div>
                <Input
                  id="code"
                  value={state?.code}
                  onChange={handleChangeState("code")}
                  error={errors?.code}
                  label="OTP*"
                  divClassName="mb-4"
                />
                <div className="mb-4 w-full">
                  <PasswordInput
                    label="Password*"
                    value={state?.password}
                    id="password"
                    onChange={handleChangeState("password")}
                    error={isError ? error?.data?.errors?.password ?? "" : ""}
                  />
                </div>
              </div>
            ) : null}
            <Button
              title={state.isOtp ? "Reset Password" : "Request OTP"}
              onClick={state.isOtp ? handleReset : handleSendOtp}
              fullWidth
              loadingTitle="Verifying"
              loading={sendingOtp || resetLoading}
              disabled={
                !state.isOtp && state.email == ""
                  ? true
                  : state.isOtp && state.code == ""
                  ? true
                  : false
              }
            />
          </div>
        }
      />
      <div className="max-w-md mx-auto pt-24">
        <div className="h-full flex items-center flex-col gap-4 justify-center border border-gray-300 shadow-md p-4 px-6 pb-6 rounded-lg">
          <img src={APP_LOGO} alt={APP_NAME} className="h-28 w-40" />
          <Input
            label="Email Address"
            value={one.email}
            id="email"
            onChange={handleChange("email")}
            error={isError ? error?.data?.errors?.email ?? "" : ""}
            onKeyDown={handleKeyDown}
            divClassName="w-full"
          />
          <div className="flex w-full flex-col">
            <PasswordInput
              label="Password"
              value={one.password}
              id="password"
              onChange={handleChange("password")}
              error={isError ? error?.data?.errors?.password ?? "" : ""}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="flex justify-end w-full text-xs hover:text-primary mb-4">
            <button onClick={handleOpen}>Forgot Passowrd?</button>
          </div>
          <Button
            title="Login"
            fullWidth
            onClick={handleLogin}
            loading={isLoading}
            loadingTitle="Logging You In"
          />
        </div>
      </div>
    </>
  )
}

export default App
