import { useAppSelector } from "@app/hooks"
import Breadcrumb from "@components/Breadcrumb"
import Checkbox from "@components/Checkbox"
import Dialog from "@components/Dialog"
import { FilterInput } from "@components/FilterInputs"
import Input from "@components/Input"
import PageContent from "@components/PageContent"
import PageHeader from "@components/PageHeader"
import { enqueueSnackbar } from "@features/auth/authSlice"
import {
  useAddMediaMutation,
  useDeleteMediaMutation,
  useLoadFileMutation,
  useRenameFileMutation,
  useSaveNewFolderMutation,
} from "@features/editorFileSelect/editorFileSelectApiSlice"
import {
  setFolderClose,
  setFolderValue,
} from "@features/editorFileSelect/editorFlieSelectSlice"
import { IMAGE_BASE } from "@utils/constants"
import { getFormData } from "@utils/getFormData"
import { queryHelper } from "@utils/queryHelper"
import qs from "query-string"
import { useEffect, useMemo, useState } from "react"
import Dropzone from "react-dropzone"
import {
  FaCheck,
  FaChevronLeft,
  FaChevronRight,
  FaEdit,
  FaEye,
  FaFolder,
  FaFolderOpen,
  FaPencilAlt,
  FaPhotoVideo,
  FaPlus,
  FaSearch,
  FaTrash,
} from "react-icons/fa"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const LinkComponent = ({ children, staticContext, ...props }) => (
  <div {...props}>{children}</div>
)

const FileList = ({ isComponent, selectFile }) => {
  // redux part & other hooks
  const { access } = useAppSelector("auth")
  const { folderName } = useAppSelector("editorFileSelect")
  const { search, pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Api call & Data Part
  const [loadFiles, { data: fileData, isLoading: fileLoading }] =
    useLoadFileMutation()
  const { path, ...queryObj } = qs.parse(search)
  const folders = fileData?.data?.folders ?? []
  const self = fileData?.data?.self ?? {
    data: [],
  }
  const files = fileData?.data?.files ?? {
    data: [],
  }
  const page = fileData?.page ?? 1
  const size = fileData?.size ?? 10
  const totalData = fileData?.totalData ?? 0

  // post/mutation part

  const [
    saveFoler,
    { isLoading: saveLoading, error: saveErrorObj, isError: saveError },
  ] = useSaveNewFolderMutation()
  const [addMediaRequest, { isLoading: addMediaLoading }] =
    useAddMediaMutation()
  const [renameFileRequest, { isLoading: renameFileLoading }] =
    useRenameFileMutation()
  const [dltFileFolder, { isLoading: deleteLoading }] = useDeleteMediaMutation()

  // State Part
  const [selectedButton, setSelectedButton] = useState("")
  const [fileCheckbox, setfileCheckbox] = useState(false)
  const [selected, setSelected] = useState("")
  const [query, setQueryValue] = useState({
    page: 1,
    search_all: "",
    search: "",
    search_all_over: "",
    size: 10,
  })
  const [dialogOpen, setDialogOpen] = useState(false)
  const [folderCheckbox, setfolderCheckbox] = useState(false)
  const [renameFile, setRenameFile] = useState({
    renamed_name: "",
    _id: null,
    open: false,
  })
  const [renameFolder, setRenameFolder] = useState({
    open: false,
    name: "",
    is_root: false,
    _id: null,
  })
  const [dltObj, setDltObj] = useState({
    file_id: [],
    folder_id: [],
  })

  const handleFetchData = () => {
    loadFiles({
      path: path,
      is_seller: false,
      queryObj: qs.stringify({ ...queryObj }),
    })
  }

  // Functionality Part
  useEffect(() => {
    handleFetchData()
  }, [search])

  const handleFolderLink = (id) => {
    // Because after each folder click page number should start from 1
    setQueryValue({ ...query, page: 1, search_all: "", search: "" })
    setSelected("")
    const searchq = qs.stringify({ ...queryObj, ...query, path: id })
    navigate(`${pathname}?${searchq}`)
  }

  const handleSingleClick = (id) => {
    if (selected === id) {
      setSelected("")
    } else {
      setSelected(id)
    }
  }

  const handleBreadCrumOnClick = (linkObj) => {
    handleFolderLink(linkObj.id)
  }

  const handleQueryChange = (name) => (event) => {
    event.persist()
    const { value } = event.target
    setQueryValue({ ...query, [name]: value })
  }

  const handleQueryEnter = (event) => {
    if (event.key === "Enter") {
      handleSearch()
    }
  }

  const handleSearch = () => {
    const searchq = qs.stringify(query)
    navigate(`${pathname}?${searchq}`)
  }

  const routeList = useMemo(() => {
    let routeList = []
    self?.path?.map((each) => {
      routeList = [
        ...routeList,
        {
          path: "/editor-file-select?CKEditor=editor1&CKEditorFuncNum=1&langCode=en",
          label: each.name,
          id: each._id,
        },
      ]
      return null
    })
    routeList = [
      ...routeList,
      {
        path: "/editor-file-select?CKEditor=editor1&CKEditorFuncNum=1&langCode=en",
        label: self.name,
        id: self._id,
      },
    ]
    return routeList
  }, [self])

  const handleFileUpload = (files, id) => {
    const formData = getFormData({}, files)
    addMediaRequest({
      formData,
      folder_id: id,
      is_seller: pathname.includes("admin") ? false : true,
    })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          dispatch(
            enqueueSnackbar({
              message: res?.msg || "File Uploaded Successfully.",
              options: {
                variant: "success",
              },
            }),
          )
        }
        loadFiles({
          path: path,
          is_seller: false,
          queryObj: qs.stringify({ ...queryObj }),
        })
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err?.msg || "File Upload Failure.",
            options: {
              variant: "warning",
            },
          }),
        )
      })
  }

  const handleAdd = () => {
    dispatch(setFolderValue({ key: "open", value: true }))
  }

  const handleSize = (value) => {
    navigate(
      `${pathname}?${queryHelper({
        ...query,
        page: 1,
        size: value,
        path: searchParams.get("path") || undefined,
      })}`,
    )
  }

  const handlePagination = ({ page, size }) => {
    navigate(
      `${pathname}?${queryHelper({
        ...query,
        page,
        size: size,
        path: searchParams.get("path") || undefined,
      })}`,
    )
  }

  const handleSaveFolder = () => {
    saveFoler({
      is_seller: pathname.includes("seller") ? true : false,
      key: self?._id ?? null,
      is_root: false,
      name: folderName.name,
    })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          dispatch(
            enqueueSnackbar({
              message: res?.msg || "Folder Created.",
              options: {
                variant: "success",
              },
            }),
          )
          handleFetchData()
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err?.msg || "Folder Created Failure.",
            options: {
              variant: "warning",
            },
          }),
        )
      })
  }

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const handleRenameButton = () => {
    if (selectedButton === "Rename") {
      setfolderCheckbox(!folderCheckbox)
    } else {
      setfolderCheckbox(true)
    }
    setfileCheckbox(false)
    let rename = selectedButton == "Rename" ? "" : "Rename"
    setSelectedButton(rename)
  }

  const handleDeleteBtn = () => {
    if (selectedButton === "Delete") {
      setfolderCheckbox(!folderCheckbox)
    } else {
      setfolderCheckbox(true)
    }
    setfileCheckbox(false)
    let rename = selectedButton == "Delete" ? "" : "Delete"
    setSelectedButton(rename)
  }

  const handleDeleteFilesFolders = () => {
    dltFileFolder(dltObj)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          dispatch(
            enqueueSnackbar({
              message: res?.msg || "Filed Delete Successfull.",
              options: {
                variant: "success",
              },
            }),
          )
          handleFetchData()
          setDltObj({
            file_id: [],
            folder_id: [],
          })
        }
      })
      .catch((err) =>
        dispatch(
          enqueueSnackbar({
            message: err?.msg || "Filed Delete Failure.",
            options: {
              variant: "warning",
            },
          }),
        ),
      )
  }

  const handleRenameFile = () => {
    renameFileRequest({
      renamed_name: renameFile.renamed_name,
      _id: renameFile._id,
    })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          dispatch(
            enqueueSnackbar({
              message: res?.msg || "Filed Renamed Successfull.",
              options: {
                variant: "success",
              },
            }),
          )
          handleFetchData()
          setRenameFile({
            renamed_name: "",
            _id: null,
            open: false,
          })
        }
      })
      .catch((err) =>
        dispatch(
          enqueueSnackbar({
            message: err?.msg || "Filed Renamed Failure.",
            options: {
              variant: "warning",
            },
          }),
        ),
      )
  }

  const handleRenameFolder = () => {
    saveFoler({
      is_seller: pathname.includes("seller") ? true : false,
      is_root: false,
      name: renameFolder.name,
      _id: renameFolder._id,
      key: self?._id ?? null,
    })
      .unwrap()
      .then((res) => {
        if (res?.success) {
          dispatch(
            enqueueSnackbar({
              message: res?.msg || "Folder Renamed.",
              options: {
                variant: "success",
              },
            }),
          )
          handleFetchData()
          setRenameFolder({
            name: "",
            _id: null,
            open: false,
          })
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: err?.msg || "Folder Rename Failure.",
            options: {
              variant: "warning",
            },
          }),
        )
      })
  }

  const handleDltObj = (key, value) => {
    const { file_id, folder_id } = dltObj
    switch (key) {
      case "folder_id":
        let arr = []
        if (folder_id.includes(value)) {
          arr = folder_id.filter((id) => id !== value)
        } else {
          arr = [...folder_id, value]
        }
        setDltObj({ ...dltObj, folder_id: arr })
        break
      case "file_id":
        let arr2 = []
        if (file_id.includes(value)) {
          arr2 = file_id.filter((id) => id !== value)
        } else {
          arr2 = [...file_id, value]
        }
        setDltObj({ ...dltObj, file_id: arr2 })
        break
    }
  }

  const onSelect = (image) => {
    if (selectFile) {
      selectFile(image)
    } else {
      window.opener.CKEDITOR.tools.callFunction(
        queryObj.CKEditorFuncNum,
        `${IMAGE_BASE}${image.path}`,
      )
    }
    if (pathname === "/editor-file-select") {
      window.close()
    }
  }

  return (
    <>
      <div className="flex justify-between mt-3 mb-3">
        {!isComponent ? <PageHeader title="Media Manage" /> : <div />}
        <div className="flex media_btn">
          {access.includes(`/admin/media-manage/add`) && (
            <>
              {self?.name?.toLowerCase() === "root" ? (
                <div
                  onClick={handleDialogOpen}
                  className="text-white items-center btn bg-success hover:bg-green-600 mr-2 cursor-pointer"
                  style={{ display: "flex" }}
                >
                  <FaPhotoVideo className="mr-2" />
                  <span>Choose File</span>
                </div>
              ) : (
                <Dropzone onDrop={(file) => handleFileUpload(file, self._id)}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="text-white btn bg-success hover:bg-green-600 mr-2 cursor-pointer">
                      <div
                        className="h-full items-center "
                        {...getRootProps()}
                        style={{ display: "flex" }}
                      >
                        <input {...getInputProps()} />
                        <FaPhotoVideo className="mr-2" />
                        <span className="inline-block">
                          {addMediaLoading ? "Uploading..." : "Choose File"}
                        </span>
                      </div>
                    </section>
                  )}
                </Dropzone>
              )}

              <button
                onClick={handleAdd}
                className="text-white items-center btn bg-orange-500 hover:bg-orange-600 mr-2"
                style={{ display: "flex" }}
              >
                <FaPlus className="mr-2" />
                <span>New Folder</span>
              </button>
            </>
          )}

          {access.includes(`/admin/media-manage/rename-folder`) && (
            <button
              onClick={handleRenameButton}
              className="text-white items-center bg-blue-500 hover:bg-blue-600 btn mr-2"
              style={{ display: "flex" }}
            >
              <FaPencilAlt className="mr-2" />
              <span>Rename</span>
            </button>
          )}

          {access.includes(`/admin/media-manage/delete`) && (
            <>
              <button
                disabled={deleteLoading}
                onClick={() => {
                  dltObj.file_id.length > 0 || dltObj.folder_id.length > 0
                    ? handleDeleteFilesFolders()
                    : handleDeleteBtn()
                }}
                className={`text-white btn items-center bg-red-600 hover:bg-red-700 flex ${
                  selectedButton === "Delete" &&
                  (dltObj.file_id.length > 0 || dltObj.folder_id.length > 0)
                    ? "animate-bounce"
                    : ""
                } disabled:cursor-not-allowed disabled:bg-opacity-40 disabled:bg-gray-400 `}
              >
                <FaTrash className="mr-2" />
                {selectedButton == "Delete" ? (
                  <>
                    {dltObj.file_id.length > 0 || dltObj.folder_id.length > 0
                      ? "Confirm Delete"
                      : "Cancel Delete"}
                  </>
                ) : (
                  "Delete"
                )}
              </button>
            </>
          )}
        </div>
      </div>
      <PageContent loading={fileLoading || deleteLoading}>
        <div className="p-2">
          <div className="flex justify-between items-center pb-3 pl-2">
            <Breadcrumb
              linkcomponent={LinkComponent}
              routeList={routeList}
              onClick={handleBreadCrumOnClick}
            />
            <div className="flex gap-2 items-center">
              <FilterInput
                name="Media Search"
                id="contents-name"
                label="Search in this folder"
                value={query.search}
                onChange={handleQueryChange("search")}
                onKeyPress={handleQueryEnter}
                style={{ minWidth: "300px", paddingRight: "50px" }}
                fieldsetId="search"
              />
              <FilterInput
                name="Media Search"
                id="contents-name"
                fieldsetId="search_all"
                label="Search in This Folder and Sub Folders"
                value={query.search_all}
                onChange={handleQueryChange("search_all")}
                onKeyPress={handleQueryEnter}
                style={{ minWidth: "300px", paddingRight: "50px" }}
              />
              <FilterInput
                type="text"
                name="Media Search"
                id="contents-name"
                fieldsetId="search_all_over"
                label="Search Files Everywhere"
                value={query.search_all_over}
                onChange={handleQueryChange("search_all_over")}
                onKeyPress={handleQueryEnter}
                style={{ minWidth: "300px", paddingRight: "50px" }}
              />
              <button
                className="bg-primary text-white p-3 rounded-md"
                onClick={handleSearch}
              >
                <FaSearch />
              </button>
            </div>
          </div>
          <div className="flex flex-wrap bg-white border border-gray-300 p-2">
            <p className="italic w-full block py-2">
              Note : Please Click the given button first for selecting{" "}
              <span className="font-bold">
                Multiple Images, Renaming folders and Deleting files
              </span>
              !!!
            </p>
            {folders?.data?.map((each) => (
              <div
                className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 select-none"
                key={`folders-${each._id}`}
              >
                <div className="h-48 mediaCont p-4 text-center border border-gray-300 -ml-px -mb-px opacity-75 hover:opacity-100 relative">
                  <div
                    className={`${folderCheckbox ? "" : "mediaCheck"} absolute`}
                  >
                    {selectedButton === "Rename" && (
                      <button
                        className="flex w-8 h-8 bg-white shadow rounded-full"
                        onClick={() =>
                          setRenameFolder({
                            name: each.name,
                            _id: each._id,
                            open: true,
                          })
                        }
                      >
                        <FaEdit
                          className="text-sm inline-block text-black m-auto hover:text-primary"
                          title="Edit"
                        />
                      </button>
                    )}
                    {selectedButton === "Delete" && (
                      <Checkbox
                        name={`secondary-${each._id}`}
                        checked={dltObj.folder_id.includes(each._id)}
                        onChange={() => handleDltObj("folder_id", each._id)}
                      />
                    )}
                  </div>
                  <div
                    className={`${
                      false === each._id ? "folder_media" : ""
                    } flex flex-col w-full h-36 text-center cursor-pointer overflow-hidden mt-10`}
                    onClick={() => handleSingleClick(each._id)}
                    onDoubleClick={() => handleFolderLink(each._id)}
                    role="presentation"
                  >
                    <div className="flex justify-center">
                      <FaFolder
                        className="text-yellow-500"
                        style={{ fontSize: "6rem" }}
                      />
                    </div>
                    <div className="block text-sm truncate">{each.name}</div>
                  </div>
                </div>
              </div>
            ))}
            {files?.data?.length
              ? files?.data?.map((each, index) => (
                  <div
                    className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 select-none"
                    key={`files-${each._id}`}
                  >
                    <div
                      className="h-48 mediaCont p-4 text-center border border-gray-300 -ml-px -mb-px opacity-75 hover:opacity-100 relative"
                      key={each._id}
                    >
                      {selectedButton === "Rename" && (
                        <div className="absolute">
                          <button
                            className="flex w-8 h-8 bg-white shadow rounded-full"
                            onClick={() =>
                              setRenameFile({
                                renamed_name: each.renamed_name,
                                _id: each._id,
                                open: true,
                              })
                            }
                          >
                            <FaEdit
                              className="text-sm inline-block text-black m-auto hover:text-primary"
                              title="Edit"
                            />
                          </button>
                        </div>
                      )}
                      <div
                        className={`${
                          fileCheckbox ? "" : "mediaCheck"
                        } absolute`}
                      >
                        {selectedButton === "Multiple" && (
                          // <Checkbox
                          //   value="primary"
                          //   color="primary"
                          //   style={{ padding: 0 }}
                          //   onClick={() => onChooseFile(each)}
                          // />
                          <div className="checkbox">
                            <input
                              // onClick={() => onChooseFile(each)}
                              id={`primary-${each._id}`}
                              type="checkbox"
                            />
                            <label htmlFor={`primary-${each._id}`}>
                              <span className="box">
                                <FaCheck className="check-icon" />
                              </span>
                            </label>
                          </div>
                        )}
                        {selectedButton === "Delete" && (
                          <Checkbox
                            name={`files-2nd-${each._id}`}
                            checked={dltObj.file_id.includes(each._id)}
                            onChange={() => handleDltObj("file_id", each._id)}
                          />
                        )}
                      </div>
                      <div className="absolute right-4">
                        <a
                          href={`${IMAGE_BASE}${each.path}`}
                          target="_blank"
                          className="flex w-8 h-8 bg-white shadow rounded-full"
                          title="View Image"
                        >
                          <FaEye
                            className="text-sm inline-block text-black m-auto hover:text-primary"
                            title="View Image"
                          />
                        </a>
                      </div>
                      <div
                        className={`${
                          selected === each._id ? "folder_media" : ""
                        } cardWrapper cardWrapperdefault flex flex-col w-full h-36 text-center cursor-pointer overflow-hidden mt-10`}
                      >
                        <div className="cardSample carddefault flex">
                          <img
                            className="w-full h-24 object-contain"
                            src={`${IMAGE_BASE}${each.path}`.replace(
                              "public/",
                              "public/200-109/",
                            )}
                            alt={each.filename}
                            onDoubleClick={() => onSelect(each)}
                            role="presentation"
                          />
                        </div>
                        <div className="truncate text-sm">
                          {each.renamed_name}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
            {folders?.data?.length < 1 && files?.data?.length < 1 && (
              <div className="h-64 flex items-center justify-center flex-col w-full">
                <FaFolderOpen
                  style={{ fontSize: "6rem" }}
                  className="mb-5 opacity-10 mx-auto"
                />
                <p className="text-gray-400">This folder is empty.</p>
              </div>
            )}
          </div>
          <div className="bg-white flex justify-between items-center">
            <div className="p-2 inline-flex items-center">
              <span className="text-xs">Show</span>{" "}
              <select
                className="inputbox text-xs w-16 p-0 mx-2"
                style={{ height: "30px" }}
                onChange={(e) => {
                  handleSize(e.target.value)
                }}
                value={size}
              >
                {[5, 10, 25, 50, 100].map((each) => (
                  <option value={each} key={`select-${each}`}>
                    {each}
                  </option>
                ))}
              </select>
              <span className="text-xs">entries</span>
              <div className="ml-2 flex items-center">
                <span
                  onClick={() => {
                    if (1 === page) {
                      return
                    }
                    handlePagination({
                      size,
                      page: page - 1,
                    })
                  }}
                  className={`${
                    1 === page
                      ? "opacity-25 pointer-events-none"
                      : "hover:bg-primary hover:text-white"
                  } w-8 h-8 rounded cursor-pointer inline-flex items-center justify-center ml-1`}
                >
                  <FaChevronLeft />
                </span>
                <span
                  onClick={() => {
                    if (Math.ceil(totalData / size) === page) {
                      return
                    }
                    handlePagination({
                      size,
                      page: page + 1,
                    })
                  }}
                  className={`${
                    Math.ceil(totalData / size) === page
                      ? "opacity-25 pointer-events-none"
                      : "hover:bg-primary hover:text-white"
                  } w-8 h-8 rounded cursor-pointer inline-flex items-center justify-center`}
                >
                  <FaChevronRight />
                </span>
              </div>
            </div>

            <div>
              <span className="mr-5 text-xs">
                Page {page} of {Math.ceil(totalData / size)}
                <span className="pl-4">Total Data : {totalData}</span>
              </span>
            </div>
          </div>
        </div>
      </PageContent>
      {/* Dialogs Start */}
      <Dialog
        open={folderName.open}
        onClose={() => dispatch(setFolderClose())}
        aria-labelledby="new-folder"
        className="w-5/6 sm:w-80"
        title={`New Folder`}
        body={
          <div className="flex flex-col p-2">
            <input
              autoFocus
              id="name"
              type="text"
              className="inputbox w-full"
              onChange={(e) =>
                dispatch(setFolderValue({ key: "name", value: e.target.value }))
              }
              value={folderName.name}
            />
            {saveErrorObj?.data?.errors ? (
              <div className="text-xs text-danger">
                {saveErrorObj.data.errors?.name}
              </div>
            ) : null}
          </div>
        }
        actions={
          <div className="m-4 w-full flex justify-between px-1">
            <button
              type="button"
              className="-ml-1 bg-gray-100 w-1/2 border border-gray-300 rounded px-3 py-2 text-sm leading-none font-bold hover:bg-gray-300"
              onClick={() => dispatch(setFolderClose())}
            >
              Cancel
            </button>
            <button
              type="button"
              className="-mr-1 bg-red-100 w-1/2 text-red-600 px-3 py-2 text-sm font-bold leading-none border border-red-300 hover:bg-red-600 hover:text-white rounded"
              onClick={handleSaveFolder}
            >
              {saveLoading ? "Saving..." : "Save"}
            </button>
          </div>
        }
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title={`Create Folder`}
        body={
          <div className="flex items-center p-2">
            <div>
              <h2 className="text-lg">Cant Upload here</h2>
              <p>
                Create Sub folder first and then only you can upload image!!
              </p>
            </div>
          </div>
        }
        actions={
          <div className="m-4 w-full flex justify-end">
            <button
              type="button"
              className="bg-gray-100 w-1/2 border rounded px-3 py-2 text-sm leading-none font-bold hover:bg-gray-300"
              onClick={handleDialogClose}
            >
              Cancel
            </button>
          </div>
        }
      />
      <Dialog
        open={renameFile.open}
        onClose={() =>
          setRenameFile({
            renamed_name: "",
            _id: null,
            open: false,
          })
        }
        title={`Rename File`}
        className="w-96"
        body={
          <div>
            <Input
              label="File Name"
              value={renameFile.renamed_name || ""}
              onChange={(e) =>
                setRenameFile({ ...renameFile, renamed_name: e.target.value })
              }
              placeholder="File Name"
            />
          </div>
        }
        actions={
          <div className="m-4 w-full flex justify-end">
            <button
              type="button"
              className="bg-gray-100 w-1/2 mr-2 border rounded px-3 py-2 text-sm leading-none font-bold hover:bg-gray-300"
              onClick={() =>
                setRenameFile({
                  renamed_name: "",
                  _id: null,
                  open: false,
                })
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className="-mr-1 bg-red-100 w-1/2 text-red-600 px-3 py-2 text-sm font-bold leading-none border border-red-300 hover:bg-red-600 hover:text-white rounded"
              onClick={handleRenameFile}
            >
              {renameFileLoading ? "Saving..." : "Save"}
            </button>
          </div>
        }
      />
      <Dialog
        open={renameFolder.open}
        onClose={() =>
          setRenameFolder({
            name: "",
            _id: null,
            open: false,
          })
        }
        title={`Rename Folder`}
        className="w-96"
        body={
          <div>
            <Input
              label="Folder Name"
              value={renameFolder.name || ""}
              onChange={(e) =>
                setRenameFolder({ ...renameFolder, name: e.target.value })
              }
              placeholder="Folder Name"
            />
          </div>
        }
        actions={
          <div className="m-4 w-full flex justify-end">
            <button
              type="button"
              className="bg-gray-100 w-1/2 mr-2 border rounded px-3 py-2 text-sm leading-none font-bold hover:bg-gray-300"
              onClick={() =>
                setRenameFolder({
                  name: "",
                  _id: null,
                  open: false,
                })
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className="-mr-1 bg-red-100 w-1/2 text-red-600 px-3 py-2 text-sm font-bold leading-none border border-red-300 hover:bg-red-600 hover:text-white rounded"
              onClick={handleRenameFolder}
            >
              {saveLoading ? "Saving..." : "Save"}
            </button>
          </div>
        }
      />
    </>
  )
}

export default FileList
