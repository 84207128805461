import { apiSlice } from "@app/api/apiSlice"

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loadCategoryList: builder.query({
      query: (query) =>
        `category/category?count=product&find_category=${query ? query : ""}`,
    }),
    loadSingleCategory: builder.mutation({
      query: (id) => ({
        url: `category/category/${id}`,
      }),
    }),
    loadProductTypeOptions: builder.query({
      query: () => `product-type?size=0`,
    }),
    saveCategory: builder.mutation({
      query: (body) => ({
        url: "category/category",
        method: "POST",
        body,
      }),
    }),
  }),
})

export const {
  useLoadCategoryListQuery,
  useLoadSingleCategoryMutation,
  useLoadProductTypeOptionsQuery,
  useSaveCategoryMutation,
} = categoryApiSlice
